import SaizImageLoader from "../saizImageLoader";

interface SaizSizeProps {
  onSizeChange: (arg: string) => void;
  size: string;
  originalMeasuredSize: string;
  productSizes: any;
  diff: number;
  outOfRange?: boolean;
}
const SaizSizeBox = ({
  size,
  diff,
  originalMeasuredSize,
  onSizeChange,
  productSizes,
  outOfRange,
}: SaizSizeProps) => {
  const approve = "icon_approve.png";
  const disapprove = "icon_dissaprove.png";
  const fits = "icon_fits.png";
  const renderIcon = () => {
    if (outOfRange) return disapprove;
    const icon =
      diff === 0
        ? approve
        : Math.abs(diff) === 1
        ? fits
        : diff < 0
        ? disapprove
        : disapprove;
    return icon;
  };
  return (
    <div
      className="saiz-size-box"
      style={{
        borderColor:
          originalMeasuredSize === size
            ? "rgba(0,0,0,0.6)"
            : "rgba(0,0,0,0.15)",
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.borderColor =
          originalMeasuredSize === size ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0.3)";
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.borderColor =
          originalMeasuredSize === size
            ? "rgba(0,0,0,0.6)"
            : "rgba(0,0,0,0.15)";
      }}
      onClick={() => {
        onSizeChange(size);
      }}
    >
      <SaizImageLoader
        item={renderIcon()}
        alt="recommended size"
        style={{
          width: "10px",
          height: "10px",
          display:
            productSizes[size!] > productSizes[originalMeasuredSize as any]
              ? "none"
              : "block",
          position: "absolute",
          zIndex: 10,
          top: "-5px",
          right: "-3px",
        }}
        key={`${size}-image`}
      />
      <span className="saiz-size-box-text">{size}</span>
    </div>
  );
};

export default SaizSizeBox;
