export const AlertConstants = {
  
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR',
}

export const AuthConstants = {

  CLEAR: 'AUTH_CLEAR',
  REFRESH_REQUEST: 'REFRESH_REQUEST',
  REFRESH_SUCCESS: 'REFRESH_SUCCESS',
  REFRESH_FAILURE: 'REFRESH_FAILURE',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT: 'LOGOUT',

  STORAGE_REQUEST: 'STORAGE_REQUEST',
  STORAGE_FEMALE: 'STORAGE_FEMALE',
  STORAGE_MALE: 'STORAGE_MALE',
  STORAGE_FAILURE: 'STORAGE_FAILURE',
  STORAGE_UPDATE: 'STORAGE_UPDATE',
}

export const ProductConstants = {

  PRODUCT_REQUEST: 'PRODUCT_REQUEST',
  PRODUCT_SUCCESS: 'PRODUCT_SUCCESS',
  PRODUCT_FAILURE: 'PRODUCT_FAILURE',
  PRODUCT_SELECT_FROM_ATTRIBUTES: 'PRODUCT_SELECT_FROM_ATTRIBUTES',
  CLEAR:'CLEAR',
}

export const CalculatorConstants = {

  GET_BODY_MEASUREMENT_REQUEST: 'GET_BODY_MEASUREMENT_REQUEST',
  GET_BODY_MEASUREMENT_SUCCESS: 'GET_BODY_MEASUREMENT_SUCCESS',
  GET_BODY_MEASUREMENT_FAILURE: 'GET_BODY_MEASUREMENT_FAILURE',
 
  GET_SESSION_REQUEST: 'GET_SESSION_REQUEST',
  GET_SESSION_SUCCESS: 'GET_SESSION_SUCCESS',
  GET_SESSION_FAILURE: 'GET_SESSION_FAILURE',

  CLEAR_BODY_MEASUREMENT: 'CLEAR_BODY_MEASUREMENT',
  GET_BODY_MEASUREMENT_NOSIZE: 'GET_BODY_MEASUREMENT_NOSIZE',


  SAVE_BASKET_SESSION_REQUEST:'SAVE_BASKET_SESSION_REQUEST',
  SAVE_BASKET_SESSION_SUCCESS:'SAVE_BASKET_SESSION_SUCCESS',
  SAVE_BASKET_SESSION_FAILURE:'SAVE_BASKET_SESSION_FAILURE',
}


export const CookieConstants={

  GET_WIDGET_COOKIE_REQUEST: 'GET_WIDGET_COOKIE_REQUEST',
  GET_WIDGET_COOKIE_SUCCESS: 'GET_WIDGET_COOKIE_SUCCESS',
  GET_WIDGET_COOKIE_FAILURE: 'GET_WIDGET_COOKIE_FAILURE',
}

export const NudgeConstants={

  GET_NUDGE_REQUEST: 'GET_NUDGE_REQUEST',
  GET_NUDGE_SUCCESS: 'GET_NUDGE_SUCCESS',
  GET_NUDGE_FAILURE: 'GET_NUDGE_FAILURE',
}

export const MeasurementConstants={
  MEASUREMENT_REQUEST: 'MEASUREMENT_REQUEST',
  MEASUREMENT_SAVE: 'MEASUREMENT_SAVE',
  MEASUREMENT_FAILURE: 'MEASUREMENT_FAILURE',
}


export const SessionConstants={
  SESSION_CREATE: 'SESSION_CREATE',
  SESSION_CLEAR: 'SESSION_CLEAR',
  SESSION_REQUEST: 'SESSION_REQUEST',
  SESSION_ERROR: 'SESSION_ERROR',
}