import React from "react";
import { connect } from "react-redux";
import "./authLayout.scss";
import { BrandConstants } from "../models";

function AuthLayout(props: any) {
  return (
    <main>
      <div className="pageWrapper-saiz">
        <div className="saiz_contentArea">
          <div
            className="saiz_wrapper"
            style={{
              paddingLeft:
                props?.brandCode === BrandConstants.TCHIBO ? "0px" : "10px",
            }}
          >
            <div>{props.children}</div>
          </div>
        </div>
      </div>
    </main>
  );
}
const mapStateToProps = (state: any) => {
  return {
    auth: state.AuthReducer,
  };
};

export default connect(mapStateToProps)(AuthLayout);
