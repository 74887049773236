import { BrandConstants } from "../models";

export interface EcentListnerConfig {
  buttonQuerySelector: string;
  variantQuerySelector: string;
  variantarrayQuerySelector: Array<string>;
  attributeSelector: string;
  variantType: VariantType;
}

export enum VariantType {
  RadioButton,
  Button,
  Ul,
  Dropdown,
}

export const Config: Record<string, EcentListnerConfig> = {
  [BrandConstants.OHAPRIL]: {
    buttonQuerySelector: "button[data-action='add-to-cart']",
    variantQuerySelector: `input[name='option-0']:checked`,
    variantType: VariantType.RadioButton,
    attributeSelector: "value",
    variantarrayQuerySelector: [],
  },
  [BrandConstants.TRIGEMA]: {
    buttonQuerySelector: "button[class='button button-primary tobasket']",
    variantQuerySelector: ".product-sizes li.selected",
    variantType: VariantType.Ul,
    attributeSelector: "data-sizecode",
    variantarrayQuerySelector: [],
  },
  [BrandConstants.BONITA]: {
    buttonQuerySelector: "button[id='add-article-to-cart']",
    variantQuerySelector:
      "button[class='btn btn-sm variant-selector btn-outline-primary']",
    variantType: VariantType.Button,
    attributeSelector: "data-variant",
    variantarrayQuerySelector: [],
  },
  [BrandConstants.RICHROYALDE]: {
    buttonQuerySelector: "button[data-action='add-to-cart']",
    variantQuerySelector: `input[name='option-1']:checked`,
    variantType: VariantType.RadioButton,
    attributeSelector: "value",
    variantarrayQuerySelector: [],
  },
  [BrandConstants.OUI]: {
    buttonQuerySelector: "button[id='add-article-to-cart']",
    variantQuerySelector:
      "button[class='btn btn-sm btn-article-size variant-selector']",
    variantType: VariantType.Button,
    attributeSelector: "data-variant",
    variantarrayQuerySelector: [],
  },
  [BrandConstants.TRIPLE2]: {
    buttonQuerySelector:
      "button[class='btn--outline btn--full btn--primary uppercase btn--add-to-cart']",
    variantQuerySelector: `input[name='options[Größe]']:checked`,
    variantType: VariantType.RadioButton,
    attributeSelector: "value",
    variantarrayQuerySelector: [],
  },
  [BrandConstants.MARCOPOLO]: {
    buttonQuerySelector: "button[data-cy='product-add-to-cart']",
    variantQuerySelector: `select[id="size"]`,
    variantType: VariantType.RadioButton,
    attributeSelector: "data-cy-selected",
    variantarrayQuerySelector: [],
  },
  [BrandConstants.TOMTAILOR]: {
    buttonQuerySelector:
      "button[class*='button--undefined button--undefined product-info__stock-button button button--primary']",
    variantarrayQuerySelector: [
      "button[class='size-button size-buttons__button']",
      "div[class='size-buttons__seletced-size-text size-buttons__seletced-size-text--bold  size-buttons__seletced-size-text--red']",
    ],
    variantType: VariantType.Button,
    attributeSelector: "textContent",
    variantQuerySelector: "",
  },
  [BrandConstants.JACKWOLFSKIN]: {
    buttonQuerySelector: "button[data-ng-click='vm.addToBasket();']",
    variantQuerySelector:
      "button[class='jwsdw-product-swatch jws-margin-right-3 jws-margin-bottom-3 jws-copy1Nano jws-fontBold jwsdw-product-swatch-active']",
    variantType: VariantType.Button,
    attributeSelector: "span",
    variantarrayQuerySelector: [],
  },
  [BrandConstants.ETERNA]: {
    buttonQuerySelector: "button[class='btn btn-primary btn-block btn-buy']",
    variantQuerySelector: "span[class='btn-text']",
    variantType: VariantType.RadioButton,
    attributeSelector: "textContent",
    variantarrayQuerySelector: [],
  },
  [BrandConstants.SCHOEFFEL]: {
    buttonQuerySelector: "button[id='add-article-to-cart']",
    variantQuerySelector: "button[class='btn dropdown-toggle btn-light']",
    variantType: VariantType.Button,
    attributeSelector: "title",
    variantarrayQuerySelector: [],
  },
};

export interface BonitaRadio {
  stock: string;
  id: string;
  size: string;
}
