import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Config, EcentListnerConfig, BonitaRadio } from "./config";
import { CalculatorActions } from "../redux/actions";
import { SaveBasketSessionCommand } from "../swagger";
import { BrandConstants } from "../models";

const EventListners = (props: any) => {
  const dispatch = useDispatch<any>();
  const brandEventListener = () => {
    try {
      const brand = Config[props.brandCode.toUpperCase()];
      if (brand) {
        addEventListenerToButton(brand, props);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const addEventListenerToButton = (brand: EcentListnerConfig, props: any) => {
    const button = document.querySelector(brand.buttonQuerySelector);
    if (button) {
      button?.addEventListener("click", function () {
        addQuerySelector(brand, props);
      });
    }
  };

  const getScreen = (step: number) => {
    switch (step) {
      case 0:
        return "Screen Bundle";
      case 1:
        return "Screen 1";
      case 2:
        return "Screen 2";
      case 3:
        return "Screen 3";
      case 4:
        return "Screen 4";
      default:
        return "Summary";
    }
  };
  const addQuerySelector = (brand: EcentListnerConfig, props: any) => {
    if (props.brandCode.toUpperCase() === BrandConstants.TOMTAILOR) {
      brand.variantarrayQuerySelector.map((variantQuerySelector) => {
        const highlightedItems =
          document.querySelectorAll(variantQuerySelector);
        highlightedItems?.forEach((variantButton: any) => {
          const variant = variantButton.textContent;
          const input = {
            bodyMeasurementRequest: {
              ...props.auth.localStorage,
              visitorId:
                props.auth.localStorage.visitorId ??
                localStorage.getItem("saiz_widget_visitorid"),
            },
            bodyMeasurementResponse: props.cal?.data?.data ?? {},
            brandCode: props.brandCode,
            productCode: props.productCode,
            variantSelected: variant,
            saizToolUsed: props.cal?.isLoaded,
            sessionId:
              props.sessionId ?? localStorage.getItem("saiz_sessionid"),
          } as SaveBasketSessionCommand;
          dispatch(CalculatorActions.saveBasketSession(props.sessionId, input));
        });
      });
    } else {
      const variantButton = document.querySelector(
        brand.variantQuerySelector
      ) as HTMLInputElement;
      if (variantButton) {
        let variant = variantButton.getAttribute(brand.attributeSelector);
        let bonita = JSON.parse("{}") as BonitaRadio;
        switch (props.brandCode.toUpperCase()) {
          case BrandConstants.BONITA:
          case BrandConstants.OUI:
            variant = variantButton.getAttribute(brand.attributeSelector);
            bonita = JSON.parse(variant ?? "{}") as BonitaRadio;
            variant = bonita.size;
            break;
          case BrandConstants.JACKWOLFSKIN:
            variant = variantButton.getElementsByTagName(
              brand.attributeSelector
            )[0].innerHTML;
            break;
          default:
            variant = variantButton.getAttribute(brand.attributeSelector);
            break;
        }
        const input = {
          bodyMeasurementRequest: {
            ...props.auth.localStorage,
            visitorId:
              props.auth.localStorage.visitorId ??
              localStorage.getItem("saiz_widget_visitorid"),
          },
          bodyMeasurementResponse: props.cal?.data?.data ?? {},
          brandCode: props.brandCode,
          productCode: props.productCode,
          variantSelected: variant,
          saizToolUsed: props.cal?.isLoaded,
          sessionId: props.sessionId ?? localStorage.getItem("saiz_sessionid"),
          saizScreenNo: getScreen(props.step),
        } as SaveBasketSessionCommand;
        dispatch(CalculatorActions.saveBasketSession(props.sessionId, input));
      }
    }
  };
  useEffect(() => {
    brandEventListener();
  }, [props.brandCode, props.productCode]);
  return <></>;
};

const mapStateToProps = (state: any, ownprops: any) => {
  return {
    auth: state.AuthReducer,
    cal: state.CalculatorReducer,
    ...ownprops,
  };
};

export default connect(mapStateToProps)(EventListners);
