import axios from "axios";
import { getCookie } from "../helpers";
const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
    "SAIZ-API-KEY": `${process.env.REACT_APP_SAIZ_API_KEY}`,
  },
  xsrfCookieName: process.env.NEXT_PUBLIC_XSRF_TOKEN,
  xsrfHeaderName: process.env.NEXT_PUBLIC_X_XSRF_TOKEN,
});
const send = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 
    'Content-Type': 'multipart/form-data',
     "SAIZ-API-KEY": `${process.env.REACT_APP_SAIZ_API_KEY}`,
   },
  xsrfCookieName: process.env.NEXT_PUBLIC_XSRF_TOKEN,
  xsrfHeaderName: process.env.NEXT_PUBLIC_X_XSRF_TOKEN,
});
http.interceptors.request.use(
  function (config: any) {
    const cookie = getCookie(process.env.REACT_APP_X_XSRF_TOKEN);
    if (cookie != undefined && cookie !== null){
      config.headers[process.env.NEXT_PUBLIC_XSRF_TOKEN??"XSRF-TOKEN"] = cookie;
      config.headers[process.env.NEXT_PUBLIC_X_XSRF_TOKEN??"X-XSRF-TOKEN"] = cookie;
    }
    return config;
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor don't delete this
http.interceptors.response.use(
  function (response: any) {
    return response.data;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);


const api = {
  get: http.get,
  post: http.post,
  put: http.put,
  delete: http.delete,
  patch: http.patch,
  send:send
};

export default api;