import { BrandConstants } from "../models";

export interface ClientSpecificConfig {
  targetQuerySelector: string;
  elementToAppend: ElementType;
  saizLink: boolean;
}

export enum ElementType {
  Image = "Image",
  SaizButton = "SaizButton",
}

export interface RecordItem {
  targetQuerySelector: string;
  saizLink: boolean;
}

export const Config = {
  [BrandConstants.BONITA]: {
    SaizButton: {
      targetQuerySelector:
        ".mt-4.d-flex.justify-content-between.align-items-center",
      saizLink: true,
    },
  },
} as Record<string, Record<string, RecordItem>>;
