export const exerciseConfig: Record<string, any> = {
    exercise: {
      default: 2,
      min: 0,
      max: 10,
    },
  }
  
  const measurementsConfigFemale: Record<string, any> = {
    age: 34,
    ...exerciseConfig,
    weight: {
      default: 75,
      min: 50,
      max: 120,
    },
    height: {
      default: 170,
      min: 150,
      max: 200,
    },
    chest: {
      default: 101,
      min: 70,
      max: 2,
    },
    waist: {
      default: 74,
      min: 50,
      max: 110,
    },
    hip: {
      default: 98,
      min: 60,
      max: 120,
    },
  }
  
  export const measurementsConfigDefault: Record<string, any> = {
    age: 34,
    ...exerciseConfig,
    weight: {
      default: 80,
      min: 50,
      max: 140,
    },
    height: {
      default: 175,
      min: 150,
      max: 210,
      
    },
    chest: {
      default: 94,
      min: 70,
      max: 120,
    },
    waist: {
      default: 82,
      min: 50,
      max: 120,
    },
    hip: {
      default: 94,
      min: 50,
      max: 120,
    },
  }
  const measurementsConfigMale: Record<string, any> = {
    age: 34,
    ...exerciseConfig,
    weight: {
      default: 85,
      min: 50,
      max: 140,
    },
    height: {
      default: 180,
      min: 150,
      max: 210,
    },
    chest: {
      default: 94,
      min: 70,
      max: 120,
    },
    waist: {
      default: 89,
      min: 60,
      max: 110,
    },
    hip: {
      default: 94,
      min: 60,
      max: 120,
    },
  }
  
  export const measurementsConfig: Record<string, any> = {
    Male: measurementsConfigMale,
    Female: measurementsConfigFemale,
  }
  
  export const getMeasurementsConfig = (
    gender: string,
  ): ((gender: string) => Record<string, any>) => measurementsConfig[gender]
  