import React, { CSSProperties } from "react";

interface IconProps {
  className?: string;
  style?: CSSProperties;
}

const Globe = ({ className, style }: IconProps) => {
  return (
    <svg
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        strokeWidth="2"
        d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

const applewoman = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="103"
        height="145"
        viewBox="1 0 103 145"
        fill="white"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M34.0016 132.894C37.804 139.168 44.6074 143 51.9437 143C59.3355 143 66.1819 139.11 69.966 132.761L73.2652 127.225C86.0601 105.755 95.0336 82.2291 99.7868 57.6924C105.381 28.8159 83.2609 2 53.8476 2H52H49.135C20.1888 2 -1.62824 28.3133 3.73317 56.7586L5.605 66.6897C9.10533 85.2609 15.9154 103.052 25.7104 119.213L34.0016 132.894Z"
          stroke="black"
          strokeWidth="4"
        />
      </svg>
    </div>
  );
};

const hourglasswoman = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="97"
        height="145"
        viewBox="0 1 97 145"
        fill="white"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M2.6118 7.29565C1.72302 4.69969 3.65225 2 6.39615 2H90.6038C93.3477 2 95.277 4.69969 94.3882 7.29566L72.9512 69.9087C72.3761 71.5884 72.3761 73.4116 72.9512 75.0913L94.3882 137.704C95.277 140.3 93.3477 143 90.6038 143H6.39615C3.65225 143 1.72302 140.3 2.61181 137.704L24.0488 75.0913C24.6239 73.4116 24.6239 71.5884 24.0488 69.9087L2.6118 7.29565Z"
          stroke="black"
          strokeWidth="4"
        />
      </svg>
    </div>
  );
};

const rectanglewoman = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="107"
        height="145"
        viewBox="0 1 90 145"
        fill="white"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          opacity="0.2"
          x="2"
          y="2"
          width="82"
          height="141"
          rx="4"
          stroke="black"
          strokeWidth="4"
        />
      </svg>
    </div>
  );
};

const pearwoman = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="100"
        height="145"
        viewBox="3 1 100 145"
        fill="white"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M13.6816 64.2865L36.526 10.8711C38.8273 5.49009 44.1158 2 49.9682 2C55.8489 2 61.1572 5.52352 63.4408 10.9428L86.259 65.0935C90.4687 75.0836 93.7653 85.4343 96.1084 96.0189C101.447 120.137 83.0918 143 58.3903 143H50H40.5777C16.3292 143 -1.74989 120.648 3.31851 96.9354C5.71071 85.7434 9.18129 74.8094 13.6816 64.2865Z"
          stroke="black"
          strokeWidth="4"
        />
      </svg>
    </div>
  );
};

const SkirtWaist = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="90"
        height="24"
        viewBox="0 0 90 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M8.1989 0H82.534L84.568 6.30791L86.602 13.1415L87.619 17.3467L89.249 23.6546H0.734863L2.85832 15.7698L5.40218 9.02612L8.1989 0Z"
          fill="url(#paint0_radial_682_642)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_682_642"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(44.9765 11.8273) rotate(90) scale(9.07678 78.2736)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const SkirtHips = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="99"
        height="35"
        viewBox="0 0 99 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M7.68162 0.654297H91.3416L93.6308 9.81094L95.9199 19.7306L97.0645 25.8351L98.2091 34.9917H0.156738L1.67111 23.5459L3.54939 14.144L7.68162 0.654297Z"
          fill="url(#paint0_radial_682_641)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_682_641"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(49.7552 19.4581) rotate(90) scale(15.5336 81.5635)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const PantsWaist = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="66"
        height="20"
        viewBox="0 0 66 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M4.12085 0H61.1149L60.3213 6.04651L60.495 8.13005C60.5988 9.37452 60.8062 10.6082 61.1149 11.8182V11.8182L61.4655 12.7633C61.9302 14.016 62.5646 15.1989 63.3509 16.2791V16.2791L65 20H0L1.88492 15.814L3.03574 12.7233C3.48016 11.5297 3.83143 10.3035 4.08638 9.05566V9.05566C4.39982 7.52153 4.50724 5.95245 4.40581 4.38992L4.12085 0Z"
          fill="url(#paint0_radial_644_1276)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_644_1276"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(32.4887 10) rotate(90) scale(7.67442 57.4798)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-main" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const PantsHips = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="78"
        height="27"
        viewBox="0 0 78 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M6.05412 0.0253906H72.5836L75.2566 7.34448L76.7417 14.6636L77.3357 19.7551L77.9297 26.756H0.708008L1.30202 17.8458L2.78705 10.5267L6.05412 0.0253906Z"
          fill="url(#paint0_radial_762_95)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_762_95"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(39.3188 14.6636) rotate(90) scale(12.0924 64.4868)"
          >
            <stop className="main-stop" />
            <stop offset="1" stopOpacity="0" className="alt-stop" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const ShirtChest = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="86"
        height="34"
        viewBox="0 0 86 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M3.26125 0.758789H81.8557L83.0003 10.6785L83.6527 12.1463C84.2341 13.4545 84.701 14.8105 85.0482 16.1993L85.0744 16.3041C85.4661 17.871 85.4661 19.5101 85.0744 21.0769L84.8909 21.8108C84.3959 23.7909 83.6314 25.6936 82.6188 27.4657V27.4657L78.8035 33.5701H6.69498L3.32955 28.5219C2.5235 27.3128 1.85557 26.0172 1.33826 24.6593V24.6593C-0.138766 20.7821 -0.336511 16.534 0.773947 12.5364L0.972086 11.8231L3.26125 0.758789Z"
          fill="url(#paint0_radial_646_1286)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_646_1286"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(42.177 17.1644) rotate(90) scale(12.5904 84.7997)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const ShirtWaist = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="73"
        height="34"
        viewBox="0 0 73 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M2.60266 0.4375H70.5144L68.6068 10.3572L68.5715 11.0072C68.4708 12.8593 68.5351 14.7168 68.7635 16.5576L68.9883 18.3692L69.7513 24.4737L70.1329 27.1444L72.0406 33.2488H0.803711L2.98426 26.3813L4.09852 18.3692V11.5018L2.60266 0.4375Z"
          fill="url(#paint0_radial_646_1287)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_646_1287"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(36.409 16.8431) rotate(90) scale(12.5904 66.4949)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const ShirtHips = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="90"
        height="20"
        viewBox="0 0 90 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M5.26111 0.40625H83.6613L85.7632 5.3661L86.594 7.27373L87.4419 9.18136L88.2921 10.8837C88.6996 11.6996 89.0444 12.5454 89.3234 13.4136V13.4136L89.96 14.9043L45.3214 19.4826L0.30127 14.1412L1.06432 12.6151L2.31525 9.18136L3.53934 5.80973L5.26111 0.40625Z"
          fill="url(#paint0_radial_646_1288)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_646_1288"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(45.1425 10.4685) rotate(90) scale(7.72219 88.2)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const DressHips = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="95"
        height="35"
        viewBox="0 0 95 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M7.05257 0.167969H88.9629L91.2041 9.32461L93.1622 17.9908C93.3505 18.8244 93.4561 19.6746 93.4772 20.529L93.5963 25.3487L94.5103 34.5054H0.654785L1.16777 23.0596L3.00677 13.6577L7.05257 0.167969Z"
          fill="url(#paint0_radial_762_540)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_762_540"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(48.2462 18.9718) rotate(90) scale(15.5336 79.8577)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const DressWaist = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="73"
        height="34"
        viewBox="0 0 73 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M2.13635 0.4375H70.5383L69.285 9.97566V13.7909V18.3692L69.6666 24.0921L70.5383 28.2889L72.0644 33.2488H0.337402L2.13635 26.3813L3.00809 18.3692V11.5018L2.13635 0.4375Z"
          fill="url(#paint0_radial_637_533)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_637_533"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(35.9427 16.8431) rotate(90) scale(12.5904 66.4949)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const TopWaist = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="70"
        height="12"
        viewBox="0 0 70 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M1.78734 10.6993H34.9801L68.2492 10.6979C68.2492 10.6979 68.173 10.4632 68.173 11.4609C68.173 11.4618 68.2959 10.2318 68.4023 9.16832C68.5037 8.15357 68.6547 7.14461 68.8547 6.1446L68.936 5.73788L69.3175 2.68566L69.6991 0.779473L35.1344 0.779634H0.26123L1.78734 10.6993Z"
          fill="url(#paint0_radial_637_840)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_637_840"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(34.9802 9.5533) rotate(-89.9197) scale(6.86748 63.4179)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const leftCaretIcon = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="10"
        height="17"
        viewBox="0 0 10 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.7">
          <line
            x1="8.33101"
            y1="0.987757"
            x2="1.33101"
            y2="8.98776"
            stroke="black"
            stroke-width="3"
          />
          <line
            x1="1.17117"
            y1="7.06279"
            x2="8.37331"
            y2="16.0628"
            stroke="black"
            stroke-width="3"
          />
        </g>
      </svg>
    </div>
  );
};

const rightCaretIcon = (
  <svg
    width="11"
    height="17"
    viewBox="0 0 11 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7">
      <line
        y1="-1.5"
        x2="10.6301"
        y2="-1.5"
        transform="matrix(0.658505 0.752577 0.752577 -0.658505 3 0)"
        stroke="black"
        stroke-width="3"
      />
      <line
        y1="-1.5"
        x2="11.527"
        y2="-1.5"
        transform="matrix(-0.624809 0.780778 0.780778 0.624809 10.2021 8)"
        stroke="black"
        stroke-width="3"
      />
    </g>
  </svg>
);

const looseIcon = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.257538 5.27381C0.139237 5.15551 0.0800875 5.0162 0.0800874 4.85586C0.0800873 4.69609 0.139237 4.55705 0.257538 4.43875L4.01532 0.680969C4.13362 0.562669 4.2728 0.503379 4.43285 0.503101C4.59291 0.503379 4.73208 0.562669 4.85038 0.680969C4.96868 0.79927 5.02797 0.938447 5.02825 1.0985C5.02797 1.25855 4.96868 1.39773 4.85038 1.51603L1.51013 4.85628L4.85038 8.19653C4.96868 8.31483 5.02783 8.45415 5.02783 8.61448C5.02783 8.77426 4.96868 8.91329 4.85038 9.0316C4.73208 9.1499 4.59304 9.20905 4.43327 9.20905C4.27294 9.20905 4.13362 9.1499 4.01532 9.0316L0.257538 5.27381ZM4.43285 5.27381C4.31455 5.15551 4.25554 5.01634 4.25582 4.85628C4.25554 4.69623 4.31455 4.55705 4.43285 4.43875L8.19063 0.680969C8.30893 0.562669 8.44825 0.503519 8.60858 0.503519C8.76836 0.503519 8.9074 0.562669 9.0257 0.680969C9.144 0.79927 9.20329 0.938447 9.20356 1.0985C9.20329 1.25855 9.144 1.39773 9.0257 1.51603L5.68545 4.85628L9.0257 8.19653C9.144 8.31483 9.20315 8.45387 9.20315 8.61365C9.20315 8.77398 9.144 8.9133 9.0257 9.0316C8.9074 9.1499 8.76822 9.20891 8.60816 9.20863C8.44811 9.20891 8.30893 9.1499 8.19063 9.0316L4.43285 5.27381Z" />
      </svg>
    </div>
  );
};

const tightIcon = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.74246 5.27381C9.86076 5.15551 9.91991 5.0162 9.91991 4.85586C9.91991 4.69609 9.86076 4.55705 9.74246 4.43875L5.98468 0.680969C5.86638 0.562669 5.7272 0.503379 5.56715 0.503101C5.40709 0.503379 5.26792 0.562669 5.14962 0.680969C5.03132 0.79927 4.97203 0.938447 4.97175 1.0985C4.97203 1.25855 5.03132 1.39773 5.14962 1.51603L8.48987 4.85628L5.14962 8.19653C5.03132 8.31483 4.97217 8.45415 4.97217 8.61448C4.97217 8.77426 5.03132 8.91329 5.14962 9.0316C5.26792 9.1499 5.40696 9.20905 5.56673 9.20905C5.72706 9.20905 5.86638 9.1499 5.98468 9.0316L9.74246 5.27381ZM5.56715 5.27381C5.68545 5.15551 5.74446 5.01634 5.74418 4.85628C5.74446 4.69623 5.68545 4.55705 5.56715 4.43875L1.80937 0.680969C1.69107 0.562669 1.55175 0.503519 1.39142 0.503519C1.23164 0.503519 1.0926 0.562669 0.974304 0.680969C0.856004 0.79927 0.796714 0.938447 0.796436 1.0985C0.796714 1.25855 0.856004 1.39773 0.974304 1.51603L4.31455 4.85628L0.974304 8.19653C0.856003 8.31483 0.796853 8.45387 0.796853 8.61365C0.796854 8.77398 0.856004 8.9133 0.974304 9.0316C1.0926 9.1499 1.23178 9.20891 1.39184 9.20863C1.55189 9.20891 1.69107 9.1499 1.80937 9.0316L5.56715 5.27381Z" />
      </svg>
    </div>
  );
};

const longIcon = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.27381 9.58328C5.15551 9.70158 5.0162 9.76073 4.85586 9.76073C4.69609 9.76073 4.55705 9.70158 4.43875 9.58328L0.680969 5.8255C0.562669 5.7072 0.503379 5.56802 0.503101 5.40797C0.503379 5.24792 0.562669 5.10874 0.680969 4.99044C0.79927 4.87214 0.938447 4.81285 1.0985 4.81257C1.25855 4.81285 1.39773 4.87214 1.51603 4.99044L4.85628 8.33069L8.19653 4.99044C8.31483 4.87214 8.45415 4.81299 8.61448 4.81299C8.77426 4.81299 8.91329 4.87214 9.0316 4.99044C9.1499 5.10874 9.20905 5.24778 9.20905 5.40755C9.20905 5.56788 9.1499 5.7072 9.0316 5.8255L5.27381 9.58328ZM5.27381 5.40797C5.15551 5.52627 5.01634 5.58528 4.85628 5.585C4.69623 5.58528 4.55705 5.52627 4.43875 5.40797L0.680969 1.65019C0.562669 1.53189 0.503519 1.39257 0.503519 1.23224C0.503519 1.07246 0.562669 0.933425 0.680969 0.815124C0.79927 0.696824 0.938447 0.637534 1.0985 0.637256C1.25855 0.637534 1.39773 0.696824 1.51603 0.815124L4.85628 4.15538L8.19653 0.815125C8.31483 0.696824 8.45387 0.637674 8.61365 0.637674C8.77398 0.637674 8.9133 0.696824 9.0316 0.815124C9.1499 0.933425 9.20891 1.0726 9.20863 1.23266C9.20891 1.39271 9.1499 1.53189 9.0316 1.65019L5.27381 5.40797Z" />
      </svg>
    </div>
  );
};

const shortIcon = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="9"
        height="10"
        viewBox="0 0 9 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.89686 0.548553C4.77856 0.430253 4.63924 0.371103 4.47891 0.371103C4.31914 0.371103 4.1801 0.430253 4.0618 0.548554L0.304016 4.30634C0.185716 4.42464 0.126426 4.56381 0.126148 4.72387C0.126426 4.88392 0.185716 5.0231 0.304016 5.1414C0.422317 5.2597 0.561494 5.31899 0.721547 5.31927C0.881601 5.31899 1.02078 5.2597 1.13908 5.1414L4.47933 1.80115L7.81958 5.1414C7.93788 5.2597 8.0772 5.31885 8.23753 5.31885C8.3973 5.31885 8.53634 5.2597 8.65464 5.1414C8.77294 5.0231 8.83209 4.88406 8.83209 4.72428C8.83209 4.56395 8.77294 4.42464 8.65464 4.30634L4.89686 0.548553ZM4.89686 4.72387C4.77856 4.60557 4.63938 4.54656 4.47933 4.54683C4.31928 4.54656 4.1801 4.60557 4.0618 4.72387L0.304016 8.48165C0.185716 8.59995 0.126565 8.73927 0.126565 8.8996C0.126565 9.05937 0.185716 9.19841 0.304016 9.31671C0.422317 9.43501 0.561494 9.4943 0.721548 9.49458C0.881601 9.4943 1.02078 9.43501 1.13908 9.31671L4.47933 5.97646L7.81958 9.31671C7.93788 9.43501 8.07692 9.49416 8.23669 9.49416C8.39703 9.49416 8.53634 9.43501 8.65464 9.31671C8.77294 9.19841 8.83195 9.05923 8.83168 8.89918C8.83195 8.73913 8.77294 8.59995 8.65464 8.48165L4.89686 4.72387Z" />
      </svg>
    </div>
  );
};

const fitIcon = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="11"
        height="9"
        viewBox="0 0 11 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.42982 6.60857L1.05919 4.23795C0.941318 4.1241 0.783442 4.0611 0.619569 4.06252C0.455696 4.06395 0.298938 4.12968 0.183058 4.24556C0.0671782 4.36144 0.00144764 4.5182 2.36266e-05 4.68207C-0.00140039 4.84594 0.0615961 5.00382 0.175445 5.12169L2.98795 7.9342C3.10515 8.05136 3.26409 8.11719 3.42982 8.11719C3.59555 8.11719 3.75449 8.05136 3.87169 7.9342L10.7467 1.05919C10.8605 0.941319 10.9235 0.783442 10.9221 0.619569C10.9207 0.455696 10.855 0.298939 10.7391 0.183058C10.6232 0.0671784 10.4664 0.00144764 10.3026 2.36265e-05C10.1387 -0.00140039 9.98082 0.0615964 9.86294 0.175445L3.42982 6.60857Z" />
      </svg>
    </div>
  );
};

const PantsLength = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="51"
        height="28"
        viewBox="0 0 51 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M0.370117 0.972788L21.1606 0.675781L20.4021 8.10094L20.2376 15.611L20.4021 20.7025L20.2376 25.3273L17.5965 26.5153L11.3594 27.7034L6.01324 26.8124L3.63719 25.3273L2.74617 18.7932L1.85515 11.368L0.370117 0.972788Z"
          fill="url(#paint0_radial_637_1273)"
        />
        <path
          opacity="0.5"
          d="M50.2671 0.675913L30.0706 0.378906L30.2351 7.80407L30.3996 15.3141L30.6647 20.5753L30.3677 25.3275L33.0407 26.2185L39.2778 27.4065L44.03 26.8125L47.594 25.6245V25.0304L48.188 18.1993L49.0791 11.0711L50.2671 0.675913Z"
          fill="url(#paint1_radial_637_1273)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_637_1273"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(12.8444 15.611) rotate(90) scale(12.0924 17.8579)"
          >
            <stop className="main-stop" />
            <stop offset="1" stopOpacity="0" className="alt-stop" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_637_1273"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(37.7928 15.3141) rotate(90) scale(12.0924 17.8579)"
          >
            <stop className="main-stop" />
            <stop offset="1" stopOpacity="0" className="alt-stop" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const JumpsuitChest = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="61"
        height="26"
        viewBox="0 0 61 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M3.22462 0.193359H57.3767L58.8319 7.79693L59.1936 8.70223C59.6517 9.84877 59.9628 11.0486 60.1197 12.2733V12.2733C60.2593 13.3628 60.1006 14.4699 59.6606 15.4763L59.2909 16.3219C58.6233 17.8488 57.8134 19.3096 56.8721 20.6848L55.9482 22.0344L54.607 25.3436H6.25715L5.74634 23.0272L3.3937 19.9775C2.69695 19.0744 2.11651 18.0872 1.66602 17.0392V17.0392C0.790603 15.0028 0.425818 12.7833 0.60337 10.5738L0.666341 9.79018L1.66634 6.15001L3.22462 0.193359Z"
          fill="url(#paint0_radial_669_971)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_669_971"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(30.0378 12.7685) rotate(90) scale(9.65069 58.4274)"
          >
            <stop className="main-stop" />
            <stop offset="1" stopOpacity="0" className="alt-stop" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const JumpsuitWaist = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="53"
        height="23"
        viewBox="0 0 53 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M3.63017 0.962891H49.2254L48.5905 7.566L48.8543 11.8834L49.2254 13.869L49.5378 15.0185C49.889 16.3105 50.3842 17.5591 51.0142 18.7405V18.7405L52.3335 22.8039H0.333496L1.84143 18.2326L3.2962 12.8993L3.70266 10.1836C3.88738 8.94943 3.95056 7.70015 3.89128 6.45368L3.63017 0.962891Z"
          fill="url(#paint0_radial_669_970)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_669_970"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(26.3244 11.8834) rotate(90) scale(8.38086 45.9839)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const JumpsuitHips = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="65"
        height="31"
        viewBox="0 0 65 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M4.73141 0.451172H61.0438L62.5846 8.39337L63.9449 15.9891C64.0651 16.6603 64.1322 17.3399 64.1455 18.0216L64.2292 22.2922L64.333 30.2344H0.333008L0.685677 20.3067L1.94997 12.1517L4.73141 0.451172Z"
          fill="url(#paint0_radial_669_972)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_669_972"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(33.0515 16.761) rotate(90) scale(13.4734 54.9012)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const JumpsuitLength = ({ className }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width="48"
        height="31"
        viewBox="0 0 48 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M0 0.989332L20 0.988281L19.3333 8.60424L19 16.5469V22.5036L18.6667 28.1293V30.1144H17.1238H11.3333H5.66667H3.33333L2.36191 20.6266L1.47619 12.4444L0 0.989332Z"
          fill="url(#paint0_radial_673_1276)"
        />
        <path
          opacity="0.5"
          d="M47.3335 0H27.3335L27.5018 8.18221L27.6702 16.4579L27.9414 22.2556V29.7832H30.3335H36.7551H41.3335H44.3335L44.6669 27.1358L45.2649 19.5246L46.3335 10.5896L47.3335 0Z"
          fill="url(#paint1_radial_673_1276)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_673_1276"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(12.4 17.1205) rotate(90) scale(13.3253 17.7515)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_673_1276"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(35.2355 16.4579) rotate(90) scale(13.3253 18.2736)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

export {
  Globe,
  applewoman,
  hourglasswoman,
  rectanglewoman,
  pearwoman,
  PantsLength,
  PantsWaist,
  PantsHips,
  SkirtWaist,
  SkirtHips,
  ShirtChest,
  ShirtWaist,
  ShirtHips,
  ShirtChest as DressChest,
  DressWaist,
  DressHips,
  TopWaist,
  ShirtChest as TopChest,
  JumpsuitWaist,
  JumpsuitHips,
  JumpsuitChest,
  JumpsuitLength,
  looseIcon,
  tightIcon,
  fitIcon,
  longIcon,
  shortIcon,
  rightCaretIcon,
  leftCaretIcon,
};
