interface SaizMainContainerProps {
  children?: React.ReactNode;
  style?: any;
}

const SaizMainContainer = ({ children, style }: SaizMainContainerProps) => {
  return (
    <div className="SaizMainContainer" style={style}>
      {children}
    </div>
  );
};

export default SaizMainContainer;
