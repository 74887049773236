import { BodyMeasurementRequestDto } from "../../models";
import { AuthConstants } from "../constants";
export const AuthActions = {
  storageUpdate,
  storageFemaleUpdate,
  storageMaleUpdate
};
function storageUpdate(data:BodyMeasurementRequestDto) {
  return { type: AuthConstants.STORAGE_UPDATE, data  };
}
function storageFemaleUpdate(data:BodyMeasurementRequestDto) {
  return { type: AuthConstants.STORAGE_FEMALE, data  };
}

function storageMaleUpdate(data:BodyMeasurementRequestDto) {
  return { type: AuthConstants.STORAGE_MALE, data  };
}
