import colors from './colors'
import fonts from './fonts'
import shadows from './shadows'
import breakpoints from './breakpoints'
import sizes from './sizes'
import spaces from './spaces'
import zIndices from './zIndices'
import { extendTheme } from "@chakra-ui/react"

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
}
export const defaultTheme = extendTheme( {
  config: {
    cssVarPrefix: 'saiz',
  },
  variable: {
    top:200,

  },
  colors,
  fonts: {
    body: "'Montserrat', sans-serif",
    heading: "'Montserrat', sans-serif",
    mono: 'Menlo, monospace',
  },
  ...fonts,
  sizes,
  breakpoints,
  shadows,
  space: spaces,
  zIndices,
  components: {
    Heading: {
      baseStyle: {
        color: 'brand.1000',
        lineHeight: 'shorter',
        textAlign: 'center',
      },
      variants: {
        saizHeading: {
          fontWeight: 'semibold',
          fontSize: ['1xl', '4xl'],
        },
      },
    },
    Flex: {
      variants: {
        saiz: {
          fontWeight: 'semibold',
          fontSize: '4xl',
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          borderColor: 'brand.500',
          borderWidth: '1px',
          _focusVisible: {
            boxShadow: 'none',
          },
          _checked: {
            bg: 'white',
            borderColor: 'brand.500',
            borderWidth: '1px',

            _hover: {
              bg: 'transparent',
            },

            _before: {
              content: `""`,
              display: 'inline-block',
              pos: 'relative',
              w: '12px',
              h: '12px',
              borderRadius: '50%',
              bg: 'brand.900',
            },
          },
        },
        label: {
          fontSize: '16px',
        },
      },
      sizes: {
        lg: {
          label: { fontSize: '16px' },
        },
      },
      
      variants: {
        saiz: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label': {
              ...activeLabelStyles,
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
      },
    }
  },
});


export const theme = extendTheme( {
  config: {
    cssVarPrefix: 'saiz-widget-container',

  },
});