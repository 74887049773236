import React, { useState } from "react";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
import styled from "styled-components";
import { BrandConstants } from "../models";
const maxAge = 100;
const minAge = 1;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;
  margin-top: 10px;
`;
interface SaizInputGroupProps {
  title: string;
  start: number;
  style?: object;
  onChange?: any;
  onChangeValue?: any;
  value: number;
  measurementUsed: string;
  brandCode: string;
}
const SaizInputGroup = ({
  title,
  style,
  onChangeValue,
  value,
  measurementUsed,
  start,
  brandCode,
}: SaizInputGroupProps) => {
  return (
    <div style={style}>
      <p className="saiz-first-capital">{title}</p>
      <FlexDiv>
        <HiMinusSm
          onClick={() => {
            const newValue = value - 1;
            if (newValue >= minAge) {
              onChangeValue(measurementUsed, newValue);
            }
          }}
          style={{ cursor: "pointer", marginBottom: "10px" }}
          size={24}
        />
        <input
          onChange={(event) => {
            event.target.value === event.target.value.replace(/^0/, "");
            const val = event.target.value;

            if (/^(?:[1-9]|[1-9][0-9]|)$/.test(val) && Number(val) < 100) {
              onChangeValue(measurementUsed, val);
            }
          }}
          value={value}
          type="text"
          onBlur={(event) => {
            if (event.target.value === "") {
              onChangeValue(measurementUsed, start);
            }
          }}
          className="saiz-input-age"
          ref={(node: any) => {
            brandCode === BrandConstants.TCHIBO &&
              node?.style.setProperty("border-radius", "40px", "important");
          }}
        />

        <HiPlusSm
          onClick={() => {
            const newValue = Number(value);
            if (newValue < maxAge) {
              onChangeValue(measurementUsed, newValue + 1);
            }
          }}
          style={{ cursor: "pointer", marginBottom: "10px" }}
          size={24}
        />
      </FlexDiv>
    </div>
  );
};

export default SaizInputGroup;
