import { useTranslation } from "react-i18next";

function Loader() {
  const { t } = useTranslation("common");

  return (
    <div
      className="d-saiz-flex justify-content-center"
      style={{
        display: "flex",
        alignItems: "baseline",
        justifyContent: "center",
        gap: "10px",
        textTransform: "capitalize",
      }}
    >
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      {t("loading")}
    </div>
  );
}
export default Loader;
