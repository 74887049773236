import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SaizImageLoader, SaizOverlayShape } from "../components";
import {
  looseIcon,
  tightIcon,
  fitIcon,
  longIcon,
  shortIcon,
} from "../assets/images/shapes/shapes";
import { getGarmentConfig } from "../config";
import { GarmentTypeDto, MeasurementsTypeEnum } from "../models";
import { useTranslation } from "react-i18next";
import { MinMaxTypeDo } from "../swagger";

function ArrowLines(props: any) {
  const { t } = useTranslation("common");
  const {
    auth,
    cal,
    arrayOfChoice,
    measuredSize,
    product,
    productCode,
    outOfRange,
    minMax,
  } = props;
  const garmentConfig: Record<string, any> = getGarmentConfig(
    auth?.localStorage?.gender
  );
  const [getConfig, setConfig] = useState(
    garmentConfig[product?.garmentType].overlays[arrayOfChoice]
  );
  useEffect(() => {
    setConfig(garmentConfig[product?.garmentType].overlays[arrayOfChoice]);
  }, [productCode]);
  const calculatedValue = () => {
    let arrayForMeasureSize = [];
    let arrayForOriginalSize = [];
    let value = 0.0;
    switch (arrayOfChoice) {
      case MeasurementsTypeEnum.Length:
        arrayForMeasureSize =
          cal?.data?.data?.calculatedProductRanges?.lengthRange.find(
            (x: any) =>
              x.item3 === measuredSize || x.item5?.includes(measuredSize)
          );
        arrayForOriginalSize =
          cal?.data?.data?.calculatedProductRanges?.lengthRange.find(
            (x: any) =>
              x.item3 === cal?.data?.data?.measuredSize ||
              x.item5?.includes(cal?.data?.data?.measuredSize)
          );
        value = cal?.data?.data?.calculatedBodyMeasurement?.length;
        break;
      case MeasurementsTypeEnum.Waist:
        arrayForMeasureSize =
          cal?.data?.data?.calculatedProductRanges?.waistRange.find(
            (x: any) =>
              x.item3 === measuredSize || x.item5?.includes(measuredSize)
          );
        arrayForOriginalSize =
          cal?.data?.data?.calculatedProductRanges?.waistRange.find(
            (x: any) =>
              x.item3 === cal?.data?.data?.measuredSize ||
              x.item5?.includes(cal?.data?.data?.measuredSize)
          );
        value = cal?.data?.data?.calculatedBodyMeasurement?.waist;
        break;
      case MeasurementsTypeEnum.Hip:
        arrayForMeasureSize =
          cal?.data?.data?.calculatedProductRanges?.hipRange.find(
            (x: any) =>
              x.item3 === measuredSize || x.item5?.includes(measuredSize)
          );
        arrayForOriginalSize =
          cal?.data?.data?.calculatedProductRanges?.hipRange.find(
            (x: any) =>
              x.item3 === cal?.data?.data?.measuredSize ||
              x.item5?.includes(cal?.data?.data?.measuredSize)
          );
        value = cal?.data?.data?.calculatedBodyMeasurement?.hip;
        break;
      default:
        arrayForMeasureSize =
          cal?.data?.data?.calculatedProductRanges?.chestRange.find(
            (x: any) =>
              x.item3 === measuredSize || x.item5?.includes(measuredSize)
          );
        arrayForOriginalSize =
          cal?.data?.data?.calculatedProductRanges?.chestRange.find(
            (x: any) =>
              x.item3 === cal?.data?.data?.measuredSize ||
              x.item5?.includes(cal?.data?.data?.measuredSize)
          );
        value = cal.data.data.calculatedBodyMeasurement.chest;
        break;
    }

    return {
      arrayForMeasureSize,
      arrayForOriginalSize,
      value,
    };
  };

  const calculateElement = (
    fit: number,
    value: number,
    min: number,
    max: number,
    diff: number
  ) => {
    const style = `${
      MeasurementsTypeEnum.Length ? "isLength" : ""
    } saiz-fit-content`;
    let element = {
      className: "fit",
      key: fit,
      text: "perfectFit",
      style: `saiz-fit-${fit}`,
    };
    if (value >= min && value <= max) {
      fit = 0;
      element = {
        className: "fit",
        key: fit,
        text: "perfectFit",
        style: `saiz-fit-0 ${style}`,
      };
    } else if (value < min) {
      element = {
        className:
          arrayOfChoice === MeasurementsTypeEnum.Length ? "long" : "loose",
        key: fit,
        text:
          diff <= 1
            ? arrayOfChoice === MeasurementsTypeEnum.Length
              ? "littleLong"
              : "littleLoose"
            : arrayOfChoice === MeasurementsTypeEnum.Length
            ? "tooLong"
            : "tooLoose",
        style: `saiz-fit-${fit} ${style}`,
      };
    } else if (value > max) {
      element = {
        className:
          arrayOfChoice === MeasurementsTypeEnum.Length ? "short" : "tight",
        text:
          diff <= 1
            ? arrayOfChoice === MeasurementsTypeEnum.Length
              ? "littleShort"
              : "littleTight"
            : arrayOfChoice === MeasurementsTypeEnum.Length
            ? "tooShort"
            : "tooTight",
        key: fit,
        style: `saiz-fit-${fit} ${style}`,
      };
    }
    return element;
  };
  const getKey = () => {
    if (outOfRange) return getOutORangeKey();
    const calData = calculatedValue();
    const arrayForMeasureSize = calData?.arrayForMeasureSize;
    const arrayForOriginalSize = calData?.arrayForOriginalSize;
    const x = arrayForOriginalSize?.item4 ?? 0;
    const y = arrayForMeasureSize?.item4 ?? 0;
    const diff = y > x ? y - x : x - y;
    const fit = diff <= 1 ? 1 : 2; //Two Red, 1 Yellow
    const min = arrayForMeasureSize?.item1;
    const max = arrayForMeasureSize?.item2;
    return calculateElement(fit, calData?.value, min, max, diff);
  };

  const getOutORangeKey = () => {
    let className = "";
    let text = "";
    switch (arrayOfChoice) {
      case MeasurementsTypeEnum.Chest:
      case MeasurementsTypeEnum.Waist:
      case MeasurementsTypeEnum.Hip:
        if (minMax === MinMaxTypeDo.Min) {
          text = "tooLoose";
          className = "loose";
        } else {
          text = "tooTight";
          className = "tight";
        }
        break;
      default:
        if (minMax === MinMaxTypeDo.Min) {
          text = "tooLong";
          className = "long";
        } else {
          text = "tooShort";
          className = "short";
        }
        break;
    }

    return {
      className,
      key: 2,
      text,
      style: `saiz-fit-${2}`,
    };
  };
  const renderArrow = () => {
    const all: any = {
      loose: looseIcon,
      tight: tightIcon,
      fit: fitIcon,
      short: shortIcon,
      long: longIcon,
    };
    const Type = all[getKey().className];
    return <Type className="arrow" />;
  };
  return (
    <>
      {product?.isLoaded &&
        cal?.isLoaded &&
        measuredSize !== undefined &&
        getConfig !== undefined && (
          <>
            <div
              className={getKey().style + " arrow-lines-container"}
              key={"key"}
              style={{
                position: "absolute",
                zIndex: 11,
                top: getConfig?.top ?? 0,
                right: "auto",
                height:
                  product.garmentType === GarmentTypeDto.Skirt &&
                  arrayOfChoice === MeasurementsTypeEnum.Waist
                    ? "24px "
                    : product,
                marginTop: "5px",
              }}
            >
              {renderArrow()}
              <p className="txt">{t(`summary.${getKey().text}`)}</p>
              <SaizImageLoader
                item={"dashed2.png"}
                style={{ height: "1px", width: "50px" }}
                alt="SAIZ"
              />
            </div>
            <div
              className="saiz-overlay-shape-container"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "0.5rem",
                position: "absolute",
                zIndex: 11,
                top: getConfig?.top ?? 0,
                left: "0",
                right: "0",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <SaizOverlayShape
                item={getConfig.image}
                fitResult={getKey().key.toString()}
                perfectFit={getKey().className === "fit"}
                keys={arrayOfChoice}
                isLength={arrayOfChoice === MeasurementsTypeEnum.Length}
              />
            </div>
          </>
        )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.AuthReducer,
    cal: state.CalculatorReducer,
    product: state.ProductReducer,
  };
};

export default connect(mapStateToProps)(ArrowLines);
