import {
  BodyShapeTypeDto,
  FitPreferenceTypeDto,
  GenderTypeDto,
} from '../../swagger'

import { AuthConstants } from '../constants'
import { measurementsConfigDefault as measurementsConfig } from '../../config/measurementsConfig'
export const authState = {
  loading: false,
  localStorage: {
    visitorId:localStorage.getItem("saiz_widget_visitorid"),
    age: measurementsConfig.age,
    height: measurementsConfig.height.default,
    bodyWeight: measurementsConfig.weight.default,
    gender: GenderTypeDto.Female,
    bodyShape: BodyShapeTypeDto.Pear,
    fitPreference: FitPreferenceTypeDto.Average,
    exercise: measurementsConfig.exercise.default,
    chest: measurementsConfig.chest.default,
    waist: measurementsConfig.waist.default,
    hip: measurementsConfig.hip.default,
    isBodyMeasured: false,
    attributeCode: null,
    reLoad:true,
    userInput:false,
  },
  maleStorage:{
  visitorId:localStorage.getItem("saiz_widget_visitorid"),
  age: measurementsConfig.age,
  height: measurementsConfig.height.default,
  bodyWeight: measurementsConfig.weight.default,
  gender: GenderTypeDto.Male,
  bodyShape: BodyShapeTypeDto.Pear,
  fitPreference: FitPreferenceTypeDto.Average,
  exercise: measurementsConfig.exercise.default,
  chest: measurementsConfig.chest.default,
  waist: measurementsConfig.waist.default,
  hip: measurementsConfig.hip.default,
  isBodyMeasured: false,
  attributeCode: null,
  reLoad:true,
  userInput:false,
  },
  femaleStorage: {
    visitorId:localStorage.getItem("saiz_widget_visitorid"),
    age: measurementsConfig.age,
    height: measurementsConfig.height.default,
    bodyWeight: measurementsConfig.weight.default,
    gender: GenderTypeDto.Male,
    bodyShape: BodyShapeTypeDto.Pear,
    fitPreference: FitPreferenceTypeDto.Average,
    exercise: measurementsConfig.exercise.default,
    chest: measurementsConfig.chest.default,
    waist: measurementsConfig.waist.default,
    hip: measurementsConfig.hip.default,
    isBodyMeasured: false,
    attributeCode: null,
    reLoad:true,
    userInput:false,
  },
  expirationDate: Date.now(),
  isLoaded: false,
}

export function AuthReducer(state = authState, action: any) {
  switch (action.type) {
    case AuthConstants.LOGIN_REQUEST:
    case AuthConstants.REFRESH_REQUEST:
    case AuthConstants.STORAGE_REQUEST:
      return { loading: true }
    case AuthConstants.STORAGE_MALE:
      return {
        ...state,
        maleStorage:{...action.data, visitorId:action.data?.visitorId??localStorage.getItem("saiz_widget_visitorid"), gender: GenderTypeDto.Male},
        loading: false,
        isLoaded: true,
      }
    case AuthConstants.STORAGE_FEMALE:
        return {
          ...state,
          femaleStorage:{...action.data, visitorId:action.data?.visitorId??localStorage.getItem("saiz_widget_visitorid"),  gender: GenderTypeDto.Female},
          loading: false,
          isLoaded: true,
        }
    case AuthConstants.LOGIN_SUCCESS:
    case AuthConstants.REFRESH_SUCCESS:
      return {
        loading: false,
        localstorage: action.user,
        expirationDate: new Date(
          new Date().getTime() + action.user.expiration * 60000,
        ),
      }
    case AuthConstants.STORAGE_UPDATE:
      return {
        ...state,
        localStorage: {...action.data, visitorId:action.data?.visitorId??localStorage.getItem("saiz_widget_visitorid")},
        expirationDate: state.expirationDate,
        isLoaded: true,
        loading: false,
      }
    case AuthConstants.REFRESH_FAILURE:
    case AuthConstants.LOGIN_FAILURE:
    case AuthConstants.STORAGE_FAILURE:
    case AuthConstants.LOGOUT:
    case AuthConstants.CLEAR:
      return {}
    default:
      return state
  }
}
export const authSelector = (state: any) => state.authState
