import {
  PantsLength,
  PantsWaist,
  PantsHips,
  SkirtWaist,
  SkirtHips,
  ShirtWaist,
  ShirtHips,
  ShirtChest,
  DressWaist,
  DressHips,
  DressChest,
  TopChest,
  TopWaist,
  JumpsuitWaist,
  JumpsuitHips,
  JumpsuitChest,
  JumpsuitLength,
} from "../../assets/images/shapes/shapes";

import {
  PantsLengthMan,
  PantsWaistMan,
  PantsHipsMan,
  ShirtChestMan,
  ShirtWaistMan,
  ShirtHipsMan,
  ShortsWaistMan,
  ShortsHipsMan,
} from "../../assets/images/shapes/shapes_man";

interface SaizOverlayProps {
  item: string;
  fitResult: string;
  perfectFit: boolean;
  keys: string;
  isLength: boolean;
  top?: string;
}
const SaizOverlayShape = ({
  item,
  fitResult,
  perfectFit,
  keys,
  isLength,
  top,
}: SaizOverlayProps) => {
  const renderShape = (item: string) => {
    const all: Record<string, any> = {
      PantsLength: PantsLength,
      PantsWaist: PantsWaist,
      PantsHips: PantsHips,
      SkirtWaist: SkirtWaist,
      SkirtHips: SkirtHips,
      ShirtChest: ShirtChest,
      ShirtHips: ShirtHips,
      ShirtWaist: ShirtWaist,
      DressWaist: DressWaist,
      DressHips: DressHips,
      DressChest: DressChest,
      TopChest: TopChest,
      TopWaist: TopWaist,
      PantsLengthMan: PantsLengthMan,
      PantsWaistMan: PantsWaistMan,
      PantsHipsMan: PantsHipsMan,
      ShirtChestMan: ShirtChestMan,
      ShirtWaistMan: ShirtWaistMan,
      ShirtHipsMan: ShirtHipsMan,
      ShortsWaistMan: ShortsWaistMan,
      ShortsHipsMan: ShortsHipsMan,
      JumpsuitWaist: JumpsuitWaist,
      JumpsuitHips: JumpsuitHips,
      JumpsuitChest: JumpsuitChest,
      JumpsuitLength: JumpsuitLength,
    };
    const Type = all[item];
    const cn = `saiz-fit-${fitResult}`;

    return (
      <Type
        className={`${cn}  ${isLength ? "islength" : ""}  ${
          perfectFit ? "perfect" : ""
        } saiz-fit-content`}
        key={keys}
      />
    );
  };
  return <>{renderShape(item)}</>;
};

export default SaizOverlayShape;
