import { CookieConstants } from '../constants'
const cookieState = {
  loading: false,
  isActive: false,
}

export function CookieReducer(state = cookieState, action: any) {
  switch (action.type) {
    case CookieConstants.GET_WIDGET_COOKIE_REQUEST:
      return { loading: true }
    case CookieConstants.GET_WIDGET_COOKIE_SUCCESS:
      return {
        loading: false,
        isActive: action.isActive,
      }
    case CookieConstants.GET_WIDGET_COOKIE_FAILURE: 
      return { loading: true, error: action.error}
    default:
      return state
  }
}
export const cookieSelector = (state: any) => state.cookieState
