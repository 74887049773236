import { Dispatch } from "redux";
import {  SessionConstants } from "../constants";
import { v4 as uuid } from "uuid";

export const SessionActions = {
    createSession,
    setSession,
};
function createSession() {
    return (dispatch: Dispatch<any>) => {
      dispatch(request())
      dispatch(success())
    }
    function request() {
      return { type: SessionConstants.SESSION_REQUEST }
    }
    function success() {
      return { type: SessionConstants.SESSION_CREATE, sessionId: uuid(),sessionCreated: (new Date()).toJSON() }
    }
    function failure(error: any) {
      return { type: SessionConstants.SESSION_ERROR, error }
    }
   
  }
  function setSession(sessionId:string) {
    return (dispatch: Dispatch<any>) => {
      dispatch(request())
      dispatch(success())
    }
    function request() {
      return { type: SessionConstants.SESSION_REQUEST }
    }
    function success() {
      return { type: SessionConstants.SESSION_CREATE, sessionId: sessionId,sessionCreated: (new Date()).toJSON() }
    }
    function failure(error: any) {
      return { type: SessionConstants.SESSION_ERROR, error }
    }
   
  }

