import { Dispatch } from "redux";
import { ProductService } from "../../services/";
import { ProductConstants } from "../constants";
import { CalculatorActions } from "./calculatorActions";
import {
  BodyMeasurementRequestDto,
  GenderTypeDto,
  NudgeDto,
  NudgeTypeDto,
  ProductDetailDto,
  ProductGenderTypeDto,
} from "../../swagger";
import { getNudgeLanguageType } from "../../utils/utils";
import { BrandConstants } from "../../models";
import { AuthActions } from "./authActions";
export const ProductActions = {
  getProductDetail,
  selectProductFromAttributes,
  clear,
  setProductDetail,
};
function getProductDetail(
  sessionId: string,
  brandCode: string,
  lang: string,
  productCode: string,
  visitorId: string,
  auth: any,
) {
  return (dispatch: Dispatch<any>) => {
    dispatch(request());
    dispatch(CalculatorActions.clear());
    lang = lang?.toLowerCase() === "cs" ? "cz" : lang;
    let product = {
      hasAttributes: false,
      productCode: productCode,
      brandCode: brandCode,
    } as ProductDetailDto;
    let recommend = false;
    let localStorage = auth?.localStorage;
    localStorage.gender = brandCode===BrandConstants.ETERNA && !localStorage.userInput ? GenderTypeDto.Male: localStorage.gender; 
    dispatch(AuthActions.storageUpdate(localStorage));
      ProductService.getProductDetail(brandCode, productCode)
      .then(
        (data: any) => {
          product = data as ProductDetailDto;
          recommend = product?.isActive && localStorage?.userInput;
          if (product.hasNudges) {
            product.nudges = getNudges(product, lang);
            product.showNudges =
              product?.nudges !== undefined && product?.nudges?.length > 0;
          }
          dispatch(success(product));
          if (switchLocalStorage(brandCode, product, auth)) {
            if (product?.productGenderType === ProductGenderTypeDto.Male) {
              localStorage = auth.maleStorage as BodyMeasurementRequestDto;
            } else if (
              product?.productGenderType === ProductGenderTypeDto.Female
            ) {
              localStorage = auth.femaleStorage as BodyMeasurementRequestDto;
            }
            recommend = product?.isActive && localStorage.userInput;
          }
          dispatch(AuthActions.storageUpdate(localStorage));
          dispatch(CalculatorActions.clear);
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        if (recommend && product?.isActive) {
          dispatch(
            CalculatorActions.recommendationAsync(sessionId, {
              exercise: localStorage.exercise,
              age: localStorage.age,
              height: localStorage.height,
              torsoLength: localStorage.torsoLength,
              bodyWeight: localStorage.bodyWeight,
              gender: localStorage.gender,
              bodyShape: localStorage.bodyShape,
              fitPreference: localStorage.fitPreference,
              emailAddress: localStorage.emailAddress,
              chest: localStorage.chest,
              waist: localStorage.waist,
              hip: localStorage.hip,
              isBodyMeasured: localStorage.isBodyMeasured,
              attributeCode: localStorage.attributeCode,
              brandCode: brandCode,
              productCode: productCode,
              visitorId:
                visitorId ?? localStorage.getItem("saiz_widget_visitorid"),
            } as BodyMeasurementRequestDto)
          );
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  function request() {
    return { type: ProductConstants.PRODUCT_REQUEST };
  }
  function success(product: any) {
    return { type: ProductConstants.PRODUCT_SUCCESS, product };
  }
  function getNudges(product: ProductDetailDto, language: string) {
    const nudges = product.nudges?.filter(
      (x: NudgeDto) =>
        (x.nudgeType === NudgeTypeDto.Influencer ||
         ( x.nudgeType === NudgeTypeDto.Message &&
        x.language === getNudgeLanguageType(language)))
    );
    return nudges;
  }
  function switchLocalStorage(
    brandCode: string,
    product: ProductDetailDto,
    auth: any
  ) {
    if (brandCode === BrandConstants.TOMTAILOR) {
      return (
        (product?.isActive &&
          auth?.localStorage.gender === GenderTypeDto.Male &&
          product?.productGenderType !== ProductGenderTypeDto.Male) ||
        (auth?.localStorage.gender === GenderTypeDto.Female &&
          product?.productGenderType !== ProductGenderTypeDto.Female)
      );
    }
    return false;
  }
}

function setProductDetail(data: any) {
  return (dispatch: Dispatch<any>) => {
    dispatch(request());
    dispatch(success(data));
  };
  function request() {
    return { type: ProductConstants.PRODUCT_REQUEST };
  }
  function success(product: any) {
    return { type: ProductConstants.PRODUCT_SUCCESS, product };
  }
}

function selectProductFromAttributes(productCode: string) {
  return (dispatch: any) => {
    dispatch(request());
  };

  function request() {
    return {
      type: ProductConstants.PRODUCT_SELECT_FROM_ATTRIBUTES,
      productCode,
    };
  }
}

function clear() {
  return (dispatch: any) => {
    dispatch(request());
  };
  function request() {
    return { type: ProductConstants.CLEAR };
  }
}
