import api from "../api";
import { BodyMeasurementRequestDto } from '../swagger'
export const CalculatorService = {
  recommendationAsync
}

async function recommendationAsync(sessionId:string,input:BodyMeasurementRequestDto) {
  return api.post(`/api/recommender/RecommendationAsync`,
    { ...input }
  );
}
