import api from "../api";
import { BrandSessionDto, SaveBasketSessionCommand } from "../swagger";

export const SessionService = {
  saveSession,
  saveBasketSession,
};

async function saveSession(sessionId: string, input: BrandSessionDto) {
  return api.post(`/api/session/SaveSessionasync/${sessionId}`,
    { ...input }
  );
}
async function saveBasketSession(
  sessionId: string,
  input: SaveBasketSessionCommand
) {
  return api.post(`/api/session/SaveBasketSessionasync/${sessionId}`,
    { ...input }
  );
}
