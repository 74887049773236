import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrandConstants, ScreenTypeDto } from "../models";
import {
  SaizMainContainer,
  SaizSlider,
  SaizImageLoader,
  InchSlider,
} from "../components";
import { ModalContext } from "../context/modalContext";
import { connect } from "react-redux";
import styled from "styled-components";
import InfoButton from "../components/InfoButton";
import { GenderTypeDto } from "../swagger";

const Container = styled.div`
  width: 50%;
  padding-inline: 40px;
  margin-top: 15px;
  order: 1;
  gap: 0;
  @media screen and (max-width: 768px) {
    width: 90%;
    order: 2;
    padding-inline: unset;
  }
`;
const Wrapper = styled.div`
  text-align: center;
  width: 50%;
  order: 2;
  padding-right: 40px;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    width: 90%;
    order: 2;
    padding-inline: unset;
  }
`;

function ScreenFour(props: any) {
  const { t } = useTranslation("common");
  const { lengthUnit } = useContext(ModalContext);
  const { auth, cal, onChange, onChangeValue, noSize, setStep, brandCode } =
    props;
  useEffect(() => {
    if (cal?.data?.noSize) {
      setStep(ScreenTypeDto.ScreenFour);
    }
  }, []);
  const SliderComponent = lengthUnit === "cm" ? SaizSlider : InchSlider;
  return (
    <SaizMainContainer>
      <h2
        style={{
          maxWidth: "600px",
          textAlign: "center",
          position: "relative",
        }}
        ref={(node: any) => {
          brandCode === BrandConstants.SCHOEFFEL &&
            node?.style.setProperty("font-size", "20px", "important");
        }}
        className={brandCode === BrandConstants.MARCOPOLO ? "uppercase" : ""}
      >
        {!noSize ? t("screenFour.heading") : t("screenFour.headingNoSize")}
        {brandCode === BrandConstants.JACKWOLFSKIN && <InfoButton />}
      </h2>
      <div
        style={{
          marginTop: brandCode === BrandConstants.MARCOPOLO ? "10px" : "35px",
        }}
        className="saiz-screenFour-container"
      >
        <Container>
          <SliderComponent
            value={
              Number.isNaN(Math.trunc(auth?.localStorage?.chest))
                ? 100
                : Math.trunc(auth?.localStorage?.chest)
            }
            title={t("screenFour.bust")}
            unit="cm"
            start={Math.trunc(auth?.localStorage?.chest)}
            minValue={40}
            maxValue={170}
            onChange={onChange}
            onChangeValue={onChangeValue}
            measurementUsed="chest"
            mt="-10px"
            style={{ marginTop: "0px" }}
          />
          <SliderComponent
            value={
              Number.isNaN(Math.trunc(auth?.localStorage?.waist))
                ? 100
                : Math.trunc(auth?.localStorage?.waist)
            }
            start={Math.trunc(auth?.localStorage?.waist)}
            title={t("screenFour.waist")}
            unit="cm"
            minValue={40}
            maxValue={170}
            onChange={onChange}
            onChangeValue={onChangeValue}
            measurementUsed="waist"
            mt="-10px"
            style={{ marginTop: "0px" }}
          />
          <SliderComponent
            value={
              Number.isNaN(Math.trunc(auth?.localStorage?.hip))
                ? 100
                : Math.trunc(auth?.localStorage?.hip)
            }
            start={Math.trunc(auth?.localStorage?.hip)}
            title={t("screenFour.hip")}
            unit="cm"
            minValue={40}
            maxValue={170}
            onChange={onChange}
            onChangeValue={onChangeValue}
            measurementUsed="hip"
            mt="-10px"
            style={{ marginTop: "0px" }}
          />
        </Container>
        <Wrapper>
          <SaizImageLoader
            item={
              auth?.localStorage?.gender === GenderTypeDto.Female
                ? "final_woman.png"
                : "final_man.png"
            }
            alt={auth?.localStorage?.gender}
            className="final_woman_img"
          />
          <p
            style={{
              textAlign: "justify",
              fontSize: "13px",
              paddingTop: "8px",
              lineHeight: "18px",
              fontWeight: "500",
              color:
                brandCode == BrandConstants.TCHIBO
                  ? "var(--saiz-widget-font-color)"
                  : "var(--saiz-colors-brand-1000)",
            }}
          >
            {!noSize
              ? t("screenFour.subHeading")
              : t("screenFour.subHeadingNoSize")}
          </p>
        </Wrapper>
      </div>
    </SaizMainContainer>
  );
}

const mapStateToProps = (state: any) => {
  return {
    cal: state.CalculatorReducer,
    product: state.ProductReducer,
    auth: state.AuthReducer,
  };
};

export default connect(mapStateToProps)(ScreenFour);
