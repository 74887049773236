import { SaizMainContainer } from "../components";
import Loader from "./loader";

const SaizLoaderScreen = () => {
  return (
    <SaizMainContainer>
      <Loader />
    </SaizMainContainer>
  );
};

export default SaizLoaderScreen;
