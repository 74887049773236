import { kgToPound, poundTokg } from "../../utils/utils";
import SaizSlider, { SaizSliderProps } from "./saizSlider";

type PoundSliderType = Omit<SaizSliderProps, "unit">;

const PoundSlider = ({
  title,
  minValue,
  maxValue,
  value = 0,
  onChange,
  onChangeValue,
  measurementUsed,
  sliderWidth,
  start,
  style,
  ...props
}: PoundSliderType) => {
  const onChangeValueForInch = (key: string, value: number) =>
    onChangeValue(key, poundTokg(value));
  return (
    <SaizSlider
      {...props}
      value={kgToPound(value)}
      title={title}
      unit="pound"
      start={kgToPound(start)}
      minValue={kgToPound(minValue)}
      maxValue={kgToPound(maxValue)}
      onChange={onChange}
      onChangeValue={onChangeValueForInch}
      measurementUsed={measurementUsed}
      style={style}
    />
  );
};

export default PoundSlider;
