import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
export interface SaizSliderProps {
  title: string;
  unit: string;
  start?: number;
  minValue: number;
  maxValue: number;
  onChange?: any;
  onChangeValue?: any;
  value?: number;
  measurementUsed?: string;
  sliderWidth?: string;
  [other: string]: any;
}
const SaizSlider = ({
  title,
  unit,
  minValue,
  maxValue,
  value = 0,
  onChangeValue,
  measurementUsed,
  sliderWidth,
  start,
  children,
  ...props
}: SaizSliderProps) => {
  return (
    <div
      {...props}
      style={{
        textAlign: "center",
        width: "100%",
        lineHeight: 1.5,
        ...props.style,
      }}
    >
      <span
        style={{
          fontSize: "var(--saiz-fontSizes-xs)",
          color: "var(--saiz-colors-brand-500)",
          marginTop: "0px",
          paddingTop: "0px",
          marginBottom: "8px",
        }}
        className="saiz-first-capital"
      >
        {title || measurementUsed}
      </span>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "484px",
          margin: "0px auto",
          position: "relative",
        }}
      >
        <span
          style={{
            fontSize: "var(--saiz-fontSizes-lg)",
            color: "var(--saiz-colors-brand-1000)",
            fontWeight: "600",
            userSelect: "none",
          }}
        >
          <span data-testid="slider-value">{value || start}</span>
          <sub style={{ bottom: "0em" }}> {unit}</sub>
        </span>
        <div
          style={{
            position: "absolute",
            right: "0px",
            bottom: "0px",
          }}
        >
          {children}
        </div>
      </div>
      <div
        style={{
          marginTop: "0px",
          display: "flex",
          width: "100%",
          alignItems: "center",
          maxWidth: "484px",
          margin: "0px auto",
          gap: "14px",
        }}
      >
        <HiMinusSm
          data-testid="slider-minus-button"
          color="brand.1000"
          style={{ cursor: "pointer" }}
          size={32}
          onClick={() => {
            if (value === minValue) return;
            const newValue = value - 1;
            onChangeValue(measurementUsed, newValue);
          }}
        />
        <Slider
          className="detect"
          aria-label="height-slider"
          value={value}
          w="100%"
          my={6}
          style={{}}
          min={minValue}
          max={maxValue}
          onChange={(event) => {
            onChangeValue(measurementUsed, event);
          }}
        >
          <SliderTrack bg="brand.200">
            <SliderFilledTrack bg="brand.1000" borderRadius={2} />
          </SliderTrack>
          <SliderThumb
            data-testid="slider-button"
            boxSize={6}
            bg="white"
            borderWidth="3px"
            borderColor="brand.1000!important"
          />
        </Slider>

        <HiPlusSm
          style={{ cursor: "pointer" }}
          data-testid="slider-plus-button"
          color="brand.1000"
          size={32}
          onClick={() => {
            if (value === maxValue) return;
            const newValue = value + 1;
            onChangeValue(measurementUsed, newValue);
          }}
        />
      </div>
    </div>
  );
};

export default SaizSlider;
