import { ScreenTypeDto } from "../models";

interface SaizNavDotsProps {
  total: number;
  current: number;
  navStep: (arg: ScreenTypeDto) => void;
}
const SaizNavDots = ({ total, current, navStep }: SaizNavDotsProps) => {
  const renderDots = () => {
    return Array.from(Array(total).keys()).map((_, index) => {
      return (
        <div onClick={() => navStep(index + 1)} key={index}>
          <div
            style={{
              background:
                current === index ? "var(--saiz-colors-brand-1000)" : "#D9D9D9",
              borderRadius: "50%",
              cursor: "pointer",
              width: "10px",
              height: "10px",
            }}
          ></div>
        </div>
      );
    });
  };
  renderDots();
  return (
    <div
      style={{
        display: "flex",
        gap: "14px",
        height: "40px",
        marginTop: "20px",
      }}
    >
      {renderDots()}
    </div>
  );
};

export default SaizNavDots;
