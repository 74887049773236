import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SaizImageLoader } from "../components";
import { getGarmentConfig } from "../config";
import { GenderTypeDto } from "../swagger";

function GarmentImage(props: any) {
  const { auth, product } = props;
  const garmentConfig: Record<string, any> = getGarmentConfig(
    auth.localStorage.gender
  );
  const [getConfig, setConfig] = useState(garmentConfig[product?.garmentType]);
  useEffect(() => {
    setConfig(garmentConfig[product?.garmentType]);
  }, []);

  return (
    <SaizImageLoader
      gender={
        auth.localStorage.gender === GenderTypeDto.Female ? "woman" : "man"
      }
      style={{ marginLeft: getConfig.ml }}
      item={getConfig.image}
      alt={auth.localStorage.gender}
    />
  );
}

const mapStateToProps = (state: any) => {
  return {
    product: state.ProductReducer,
    auth: state.AuthReducer,
  };
};

export default connect(mapStateToProps)(GarmentImage);
