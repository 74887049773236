import { BrandConstants } from "../models";
import ReactGA from "react-ga4";
export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};
const getCookie=(key: string) =>{
  const b = RegExp("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)").exec(document.cookie);
  return b ? b.pop() : "";
}
// Log a custom event
export const logEvent = (action: string, label?: string, properties?: Record<string, any>) => {
  ReactGA.send({ hitType: 'event', eventCategory: "saiz", eventAction: action, eventLabel: label, properties});
};
// Set user properties
export const setUserProperties = (userId: string, userProperties: Record<string, any>) => {
  ReactGA.send({ hitType: 'event', eventCategory: 'saiz', eventAction: 'set', userId, ...userProperties });
};
export const trackWindowDataLayer = (eventName: string, eventProperties: any)=> {
      window?.dataLayer?.push({
        event: eventName,
        data: eventProperties,
      });
};
export const initializeTagManager = (brandCode: string)=> {
    if(process.env.REACT_APP_SAIZ_ANALYTICS === "true" && localStorage.getItem("saiz_ga") ==="true" ){
      const tagManager = GtmId[brandCode];
      if(tagManager?.isActive) {
      ReactGA.initialize(`${tagManager.gtImd}`);
    }
  }
  };
export interface IGtmDataLayer {
    gtImd: string;
    isActive: boolean;
  }
  export const GtmId: Record<string, IGtmDataLayer> = {
    [BrandConstants.MARCOPOLO]: {
     gtImd:"G-740ER9CYK0",
     isActive: getCookie("privacy-opt-out-performance") === "1"
    },
    [BrandConstants.OHAPRIL]: {
      gtImd:"G-CR0WQHCVQ2",
      isActive:true
     },
     [BrandConstants.TRIGEMA]: {
      gtImd:"G-TF438PPWV2",
      isActive:true
     },
     [BrandConstants.RICHROYALDE]: {
      gtImd:"G-L8D9GDNGEC",
      isActive:true
     },
     [BrandConstants.TRIPLE2]: {
      gtImd:"G-95GJDZT54Z",
      isActive:true
     },
     [BrandConstants.TOMTAILOR]: {
      gtImd:"G-GGLH37WR87",
      isActive:true
     },
     [BrandConstants.OUI]: {
      gtImd:"G-KYF6N9WLE3",
      isActive:true
     },
     [BrandConstants.JACKWOLFSKIN]: {
      gtImd:"G-T7CEK06DL9",
      isActive:true
     },
     [BrandConstants.ETERNA]: {
      gtImd:"G-S21PHP02XL",
      isActive:true
     },
     [BrandConstants.SCHOEFFEL]: {
      gtImd:"G-W5G5PQNKP8",
      isActive:true
     },
     [BrandConstants.DRYKORN]: {
      gtImd:"G-4CQD1SPWNX",
      isActive:true
     },
     [BrandConstants.BETTY]: {
      gtImd:"G-YS4JTYTHQS",
      isActive:true
     },
     [BrandConstants.ZERO]: {
      gtImd:"G-XZCHXKRTKH",
      isActive:true
     },
     [BrandConstants.SOLIVER]: {
      gtImd:"G-M0WB3Q3FPD",
      isActive:true
     },
     [BrandConstants.TFS]: {
      gtImd:"G-KTZ0VTNDX2",
      isActive:true
     },
     [BrandConstants.COMMA]: {
      gtImd:"G-3CP3X70H2T",
      isActive:true
     },
     [BrandConstants.TCHIBO]: {
      gtImd:"G-B4PGWKX3CD", //"G-B4HHRDBXML",//
      isActive:true
     },
  };
