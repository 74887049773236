import { enabledBrandNames } from "./config";
import "./SaizUnitToggler.scss";
import styled from "styled-components";

interface SaizUnitTogglerProps {
  value: string;
  left: string;
  right: string;
  setValue: (_: string) => void;
  brandCode: string;
}

const Text = styled.p`
  font-size: 14px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

const SaizUnitToggler = ({
  value,
  left,
  right,
  setValue,
  brandCode,
}: SaizUnitTogglerProps) => {
  const isLeft = value === left;
  const swtichOrigin = () => setValue(isLeft ? right : left);

  if (
    !enabledBrandNames.map((item) => item?.toLowerCase())?.includes(brandCode)
  )
    return <></>;

  return (
    <div className="saiz-toggle-container">
      <Text>{left}</Text>

      <label className="saiz-toggle-label">
        <input
          type="checkbox"
          className="saiz-toggle-input"
          defaultChecked={!isLeft}
          onClick={swtichOrigin}
        />
        <span className="saiz-toggle-span" />
      </label>
      <Text>{right}</Text>
    </div>
  );
};

export default SaizUnitToggler;
