import { cmToInch, inchToCm } from "../../utils/utils";
import SaizSlider, { SaizSliderProps } from "./saizSlider";

type InchSliderType = Omit<SaizSliderProps, "unit">;
const InchSlider = ({
  title,
  minValue,
  maxValue,
  value = 0,
  onChange,
  onChangeValue,
  measurementUsed,
  sliderWidth,
  start,
  style,
  ...props
}: InchSliderType) => {
  const onChangeValueForInch = (key: string, value: number) =>
    onChangeValue(key, inchToCm(value));
  return (
    <SaizSlider
      {...props}
      value={cmToInch(value)}
      title={title}
      unit="inch"
      start={cmToInch(start)}
      minValue={cmToInch(minValue)}
      maxValue={cmToInch(maxValue)}
      onChange={onChange}
      onChangeValue={onChangeValueForInch}
      measurementUsed={measurementUsed}
      style={style}
    />
  );
};

export default InchSlider;
