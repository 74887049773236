import { useContext } from "react";
import { createPortal } from "react-dom";
import { ModalContext } from "../../context/modalContext";
import SaizImageLoader from "../saizImageLoader";
import "./SaizLogo.scss";

type SaizLogoProps = {
  elem: HTMLDivElement | null;
};
export default function SaizLogo({ elem }: SaizLogoProps) {
  return elem ? createPortal(<SaizLogoContent />, elem) : null;
}

const SaizLogoContent = () => {
  const { setModal } = useContext(ModalContext);
  return (
    <SaizImageLoader
      item="saiz-big.png"
      style={{ width: "49px", cursor: "pointer" }}
      alt="saiz-logo"
      onClick={() => {
        setModal(true);
      }}
    />
  );
};
