interface SaizBundleProps {
  onSizeChange: (arg: string) => void;
  size: string;
  [other: string]: any;
}
const SaizBundleBox = ({ size, onSizeChange, ...props }: SaizBundleProps) => {
  return (
    <div
      onClick={() => {
        onSizeChange(size);
      }}
      {...props}
    >
      <span
        style={{
          fontSize: "var(--saiz-fontSizes-xs)",
          userSelect: "none",
        }}
      >
        {size}
      </span>
    </div>
  );
};

export default SaizBundleBox;
