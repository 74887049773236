import { SessionConstants } from "../constants";

export interface SessionState {
  sessionId?: string;
  sessionCreated?: string;
  loading?: boolean;
  isLoaded?: boolean;
}

export const sessionState = {
  sessionId: "",
  sessionCreated: "",
  loading: false,
  isLoaded: false,
} as SessionState;

export function SessionReducer(state = sessionState, action: any) {
  switch (action.type) {
    case SessionConstants.SESSION_REQUEST:
      return { loading: true };
    case SessionConstants.SESSION_CREATE:
      localStorage.setItem("saiz_session_id", action.sessionId);
      localStorage.setItem("saiz_sessionid", action.sessionId);
      localStorage.setItem("saiz_sessionId", action.sessionId);

      return {
        loading: false,
        sessionId: action.sessionId,
        sessionCreated: action.sessionCreated,
        isLoaded: true,
      };
    case SessionConstants.SESSION_CLEAR:
      return {};
    default:
      return state;
  }
}

export const sessionSelector = (state: any) => state.sessionState;
