import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { SaizMainContainer, SaizBundleBox } from "../components";
import { ProductActions } from "../redux/actions";
import { BrandConstants } from "../models";

function ScreenOneBundle(props: any) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch<any>();
  const { onChangeAttributeCode, product, auth, brandCode } = props;

  return (
    <SaizMainContainer>
      <h2
        style={{ textAlign: "center", marginBottom: "100px" }}
        className={brandCode === BrandConstants.MARCOPOLO ? "uppercase" : ""}
      >
        {t("screenOne.heading")}
      </h2>

      <div className="screen-one-bundle-wrapper ">
        {product.productAttributes.map((item: any) => (
          <SaizBundleBox
            key={`${item.productCode}-bundle`}
            size={item.productName}
            className={
              auth?.localStorage?.attributeCode === item.productCode
                ? "selected-bundle bundle-size-box"
                : "bundle-size-box"
            }
            onSizeChange={() => {
              dispatch(
                ProductActions.selectProductFromAttributes(item?.productCode)
              );
              onChangeAttributeCode(item?.productCode);
            }}
          />
        ))}
      </div>
    </SaizMainContainer>
  );
}

const mapStateToProps = (state: any) => {
  return {
    product: state.ProductReducer,
    auth: state.AuthReducer,
  };
};

export default connect(mapStateToProps)(ScreenOneBundle);
