import { GarmentTypeDto } from "../models";

const garmentConfigFemale: Record<string, any> = {
  [GarmentTypeDto.Bottom_Underwear_Bikini]: {
    //DONE
    image: "Skirt_Woman.png",
    topOffset: "176px",
    overlays: {
      waist: { image: "SkirtWaist", top: "165px" },
      hip: { image: "SkirtHips", top: "188px" },
    },
  },
  [GarmentTypeDto.Bra]: {
    //DONE
    image: "Top_Woman.png",
    top: "80px",
    overlays: {
      chest: { image: "TopChest", top: "100px" },
      waist: { image: "TopWaist", top: "130px" },
      hip: { image: "ShirtHips", top: "185px" },
    },
  },
  [GarmentTypeDto.Dress]: {
    //DONE
    image: "Dress_Woman.png",
    top: "80px",
    overlays: {
      chest: { image: "DressChest", top: "100px" },
      hip: { image: "DressHips", top: "175px" },
      waist: { image: "DressWaist", top: "140px" },
      length: { image: "PantsLengthMan", top: "240px" },
    },
  },
  //DONE
  [GarmentTypeDto.Jacket]: {
    image: "Shirt_Long_Woman.png",
    top: "170px",
    ml: "4px",
    overlays: {
      chest: { image: "ShirtChest", top: "100px" },
      hip: { image: "ShirtHips", top: "175px" },
      waist: { image: "ShirtWaist", top: "140px" },
    },
  },

  [GarmentTypeDto.Jumpsuit]: {
    //DONE
    image: "Jumpsuit_Woman.png",
    top: "80px",
    overlays: {
      chest: { image: "JumpsuitChest", top: "60px" },
      waist: { image: "JumpsuitWaist", top: "90px" },
      hip: { image: "JumpsuitHips", top: "115px" },
      length: { image: "JumpsuitLength", top: "240px" },
    },
  },
  [GarmentTypeDto.Leggings]: {
    //DONE
    image: "Pants_Woman.png",
    ml: "5px",
    overlays: {
      waist: { image: "PantsWaist", top: "35px" },
      hip: { image: "PantsHips", top: "60px" },
      length: { image: "PantsLength", top: "220px" },
    },
  },
  [GarmentTypeDto.Pants]: {
    //DONE
    image: "Pants_Woman.png",
    ml: "5px",
    overlays: {
      waist: { image: "PantsWaist", top: "35px" },
      hip: { image: "PantsHips", top: "60px" },
      length: { image: "PantsLength", top: "220px" },
    },
  },
  [GarmentTypeDto.Shirt]: {
    //DONE
    image: "Shirt_Woman.png",
    top: "170px",
    overlays: {
      chest: { image: "ShirtChest", top: "100px" },
      waist: { image: "ShirtWaist", top: "140px" },
      hip: { image: "ShirtHips", top: "176px" },
    },
  },
  [GarmentTypeDto.Skirt]: {
    //DONE
    image: "Skirt_Woman.png",
    topOffset: "176px",
    overlays: {
      waist: { image: "SkirtWaist", top: "160px" },
      hip: { image: "SkirtHips", top: "180px" },
      length: { image: "PantsLengthMan", top: "240px" },
    },
  },
  [GarmentTypeDto.Shorts]: {
    //DONE
    image: "Shorts_Woman.png",
    topOffset: "176px",
    overlays: {
      waist: { image: "SkirtWaist", top: "160px" },
      hip: { image: "SkirtHips", top: "180px" },
      length: { image: "PantsLengthMan", top: "240px" },
    },
  },
  [GarmentTypeDto.Sweater]: {
    //DONE
    image: "Shirt_Long_Woman.png",
    top: "170px",
    ml: "4px",
    overlays: {
      chest: { image: "ShirtChest", top: "100px" },
      waist: { image: "ShirtWaist", top: "140px" },
      hip: { image: "ShirtHips", top: "185px" },
    },
  },
  [GarmentTypeDto.TurtleNeck]: {
    //DONE
    image: "Shirt_Long_Woman.png",
    top: "170px",
    ml: "4px",
    overlays: {
      chest: { image: "ShirtChest", top: "100px" },
      hip: { image: "ShirtHips", top: "185px" },
      waist: { image: "ShirtWaist", top: "140px" },
    },
  },
};

const garmentConfigMale: any = {
  [GarmentTypeDto.Bottom_Underwear_Bikini]: {
    //DONE
    image: "Shorts_Man.png",
    top: "-2px",
    overlays: {
      waist: { image: "ShortsWaistMan", top: "63px" },
      hip: { image: "ShortsHipsMan", top: "85px" },
    },
  },
  [GarmentTypeDto.Bra]: {
    //DONE
    image: "Shirt_Man.png",
    topOffset: "176px",
    overlays: {
      chest: { image: "TopChest", top: "120px" },
      waist: { image: "SkirtWaist", top: "170px" },
      hip: { image: "SkirtHips", top: "188px" },
    },
  },
  [GarmentTypeDto.Dress]: {
    //DONE
    image: "Shirt_Man.png",
    topOffset: "176px",
    overlays: {
      chest: { image: "ShirtChest", top: "120px" },
      waist: { image: "SkirtWaist", top: "170px" },
      hip: { image: "SkirtHips", top: "200px" },
    },
  },
  //DONE
  [GarmentTypeDto.Jacket]: {
    image: "Shirt_Long_Man.png",
    top: "170px",
    ml: "4px",
    overlays: {
      chest: { image: "ShirtChest", top: "120px" },
      waist: { image: "ShirtWaist", top: "160px" },
      hip: { image: "ShirtHips", top: "185px" },
    },
  },

  [GarmentTypeDto.Jumpsuit]: {
    //DONE
    image: "Jumpsuit_Woman.png",
    top: "80px",
    overlays: {
      chest: { image: "JumpsuitChest", top: "60px" },
      waist: { image: "JumpsuitWaist", top: "90px" },
      hip: { image: "JumpsuitHips", top: "115px" },
      length: { image: "JumpsuitLength", top: "240px" },
    },
  },
  [GarmentTypeDto.Pants]: {
    //DONE
    image: "Pants_Man.png",
    ml: "-2px",
    overlays: {
      waist: { image: "PantsWaistMan", top: "63px" },
      length: { image: "PantsLengthMan", top: "240px" },
      hip: { image: "PantsHipsMan", top: "80px" },
    },
  },
  [GarmentTypeDto.Shirt]: {
    //DONE
    image: "Shirt_Man.png",
    topOffset: "176px",
    overlays: {
      chest: { image: "ShirtChest", top: "115px" },
      waist: { image: "SkirtWaist", top: "165px" },
      hip: { image: "SkirtHips", top: "188px" },
    },
  },
  [GarmentTypeDto.Skirt]: {
    //DONE
    image: "Shorts_Man.png",
    top: "-2px",
    overlays: {
      waist: { image: "ShortsWaistMan", top: "63px" },
      hip: { image: "ShortsHipsMan", top: "85px" },
    },
  },
  [GarmentTypeDto.Shorts]: {
    //DONE
    image: "Shorts_Man.png",
    top: "-2px",
    overlays: {
      waist: { image: "ShortsWaistMan", top: "63px" },
      hip: { image: "ShortsHipsMan", top: "85px" },
    },
  },
  [GarmentTypeDto.Sweater]: {
    //DONE
    image: "Shirt_Long_Man.png",
    top: "170px",
    overlays: {
      chest: { image: "ShirtChestMan", top: "115px" },
      hip: { image: "ShirtHipsMan", top: "208px" },
      waist: { image: "ShirtWaistMan", top: "155px" },
    },
  },
  [GarmentTypeDto.Leggings]: {
    //DONE
    image: "Pants_Man.png",
    ml: "-2px",
    overlays: {
      waist: { image: "PantsWaistMan", top: "63px" },
      length: { image: "PantsLengthMan", top: "240px" },
      hip: { image: "PantsHipsMan", top: "80px" },
    },
  },
  [GarmentTypeDto.TurtleNeck]: {
    //DONE
    image: "Shirt_Long_Man.png",
    top: "170px",
    overlays: {
      chest: { image: "ShirtChestMan", top: "115px" },
      hip: { image: "ShirtHipsMan", top: "208px" },
      waist: { image: "ShirtWaistMan", top: "155px" },
    },
  },
};

export const garmentConfig: Record<string, any> = {
  Male: garmentConfigMale,
  Female: garmentConfigFemale,
};

export const getGarmentConfig = (
  gender: string
): ((gender: string) => Record<string, any>) => garmentConfig[gender];

export const textConfig: { [key: string]: string } = {
  "0": "perfectFit",
  "1": "littleLoose",
  "2": "tooLoose",
  "3": "tooLoose",
  "4": "tooLoose",
  "5": "tooLoose",
  "6": "tooLoose",
  "7": "tooLoose",
  "-1": "littleTight",
  "-2": "tooTight",
  "-3": "tooTight",
  "-4": "tooTight",
  "-5": "tooTight",
  "-6": "tooTight",
  "-7": "tooTight",
  undefined: "undefined",
};

export const textConfigLength: { [key: string]: string } = {
  "0": "perfectFit",
  "1": "littleLong",
  "2": "littleLong",
  "3": "littleLong",
  "4": "littleLong",
  "5": "littleLong",
  "6": "littleLong",
  "7": "littleLong",
  "-1": "littleShort",
  "-2": "littleShort",
  "-3": "littleShort",
  "-4": "littleShort",
  "-5": "littleShort",
  "-6": "littleShort",
  "-7": "littleShort",
  undefined: "undefined",
};

export const THRESHOLD_SIZE_DIFF = 3;

export const maxItemsPerSlide = 5;
