import React from "react";

import { IconProps } from "../../../models";

const PantsWaistMan = ({ className, style }: IconProps): JSX.Element => {
  return (
    <div className={className} style={style}>
      <svg
        width="73"
        height="18"
        viewBox="0 0 73 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M3.3629 0.662109H69.2191L69.782 3.58489L70.6263 7.80667L71.4706 12.678L72.0335 17.5493L0.26709 17.874L1.1114 11.7037L2.23715 5.5334L3.3629 0.662109Z"
          fill="url(#paint0_radial_820_169)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_820_169"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(36.1378 9.26806) rotate(90) scale(6.60457 63.4634)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const PantsHipsMan = ({ className, style }: IconProps) => {
  return (
    <div className={className} style={style}>
      <svg
        width="82"
        height="31"
        viewBox="0 0 82 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M3.72761 0.859375H77.9032L79.1496 9.13433L80.7077 22.7053L81.6425 30.6492H0L0.934838 24.3603L2.18144 12.1133L3.72761 0.859375Z"
          fill="url(#paint0_radial_820_168)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_820_168"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(46.4376 17.1729) rotate(90) scale(13.4764 67.1417)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const PantsLengthMan = ({ className, style }: IconProps) => {
  return (
    <div className={className} style={style}>
      <svg
        width="54"
        height="30"
        viewBox="0 0 54 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M0 0.663161L23.0443 0.662109L22.3858 7.94407L21.3982 16.219L21.069 22.177L20.4106 28.797L18.7646 29.128L13.0584 29.7899H6.52921L3.95044 27.142L2.63363 17.874L1.31681 8.93706L0 0.663161Z"
          fill="url(#paint0_radial_671_1273)"
        />
        <path
          opacity="0.5"
          d="M53.9893 0H30.945L31.9326 7.94396L32.9202 16.5499L33.5406 22.2605L34.237 28.7968L35.9818 29.7898H42.5352H47.2077L50.0388 28.7968L50.368 27.4729L51.3556 19.5289L52.6724 10.5919L53.9893 0Z"
          fill="url(#paint1_radial_671_1273)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_671_1273"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(11.34 15.226) rotate(90) scale(11.177 22.6924)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_671_1273"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(42.6493 14.8949) rotate(90) scale(11.431 22.6924)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const ShirtChestMan = ({ className, style }: IconProps) => {
  return (
    <div className={className} style={style}>
      <svg
        width="91"
        height="40"
        viewBox="0 0 91 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M1.20734 0.65625H89.3996L88.8445 6.46347L89.8261 12.2707L89.8826 16.2911L90.4235 20.5483C90.5168 21.2825 90.5475 22.0233 90.5154 22.7627L90.4958 23.2122C90.3348 26.9122 89.5748 30.5616 88.2455 34.0184L86.2134 39.0732H4.79186L4.01793 35.5348L3.16539 33.6769C1.68771 30.4567 0.860947 26.9762 0.73262 23.4354L0.667969 21.6516L1.12269 13.6108L2.58882 5.26629L1.20734 0.65625Z"
          fill="url(#paint0_radial_820_71)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_820_71"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(44.9052 19.8647) rotate(90) scale(14.7414 88.5232)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const ShirtWaistMan = ({ className, style }: IconProps) => {
  return (
    <div className={className} style={style}>
      <svg
        width="80"
        height="34"
        viewBox="0 0 80 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M0.0463867 0.767578H79.9537L78.7781 10.7793V17.2869V27.7992V33.8062H0.0463514L0.40309 26.798V18.2881V12.281L0.0463867 0.767578Z"
          fill="url(#paint0_radial_820_72)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_820_72"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(38.7925 17.2869) rotate(90) scale(12.6776 72.7728)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const ShirtHipsMan = ({ className, style }: IconProps) => {
  return (
    <div className={className} style={style}>
      <svg
        width="91"
        height="29"
        viewBox="0 0 91 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M3.8929 0.246094H87.352L88.1726 4.08779L89.7709 12.1554L90.5637 15.5701V16.3812L66.5961 25.9855L46.2271 28.6747L26.4664 26.583L13.454 21.7596L0.667969 16.0284L0.937535 15.2801C1.45356 13.8477 1.847 12.374 2.11362 10.8749L2.2805 9.93658L3.0867 5.29518L3.8929 0.246094Z"
          fill="url(#paint0_radial_820_73)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_820_73"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(45.6002 14.4604) rotate(90) scale(10.9086 79.4953)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const ShortsWaistMan = ({ className, style }: IconProps) => {
  return (
    <div className={className} style={style}>
      <svg
        width="77"
        height="16"
        viewBox="0 0 77 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M3.39961 0.296875H73.5996L74.1996 2.96248L75.0996 6.8128L75.9996 11.2555L76.5996 15.6981L0.0996094 15.9943L0.999609 10.3669L2.19961 4.73955L3.39961 0.296875Z"
          fill="url(#paint0_radial_820_411)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_820_411"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(38.3363 8.1456) rotate(90) scale(6.02344 67.6494)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const ShortsHipsMan = ({ className, style }: IconProps) => {
  return (
    <div className={className} style={style}>
      <svg
        width="84"
        height="28"
        viewBox="0 0 84 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M3.68499 0.771484H81.1993L82.2522 8.17594L83.2258 20.3193V27.4275H0.799316L1.39932 19.4307L2.08739 10.8415L3.68499 0.771484Z"
          fill="url(#paint0_radial_820_410)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_820_410"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(42.9861 15.3688) rotate(90) scale(12.0587 69.3752)"
          >
            <stop className="main-stop" />
            <stop offset="1" className="alt-stop" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

const appleman = ({ className, style }: IconProps) => {
  return (
    <div className={className} style={style}>
      <svg
        width="103"
        height="142"
        viewBox="1 1 100 149"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M34.0016 132.894C37.804 139.168 44.6074 143 51.9437 143C59.3355 143 66.1819 139.11 69.966 132.761L73.2652 127.225C86.0601 105.755 95.0336 82.2291 99.7868 57.6924C105.381 28.8159 83.2609 2 53.8476 2H52H49.135C20.1888 2 -1.62824 28.3133 3.73317 56.7586L5.605 66.6897C9.10533 85.2609 15.9154 103.052 25.7104 119.213L34.0016 132.894Z"
          stroke="black"
          strokeWidth="4"
        />
      </svg>
    </div>
  );
};

const pearman = ({ className, style }: IconProps) => {
  return (
    <div className={className} style={style}>
      <svg
        width="86"
        height="143"
        viewBox="0 1 86 149"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          opacity="0.2"
          x="2"
          y="2"
          width="82"
          height="141"
          rx="4"
          stroke="black"
          strokeWidth="4"
        />
      </svg>
    </div>
  );
};

const rectangleman = ({ className, style }: IconProps) => {
  return (
    <div className={className} style={style}>
      <svg
        width="100"
        height="140"
        viewBox="-1 1 103 145"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M36.526 10.8711C38.8273 5.49009 44.1158 2 49.9682 2C55.8489 2 61.1572 5.52352 63.4408 10.9428L86.259 65.0935C90.4687 75.0836 93.7653 85.4343 96.1085 96.0189C101.447 120.137 83.0918 143 58.3903 143H50H40.5777C16.3292 143 -1.74989 120.648 3.31851 96.9354C5.71071 85.7434 9.18129 74.8094 13.6816 64.2865L36.526 10.8711Z"
          stroke="black"
          strokeWidth="4"
        />
      </svg>
    </div>
  );
};

export {
  PantsWaistMan,
  PantsHipsMan,
  PantsLengthMan,
  ShirtChestMan,
  ShirtWaistMan,
  ShirtHipsMan,
  ShortsWaistMan,
  ShortsHipsMan,
  appleman,
  pearman,
  rectangleman,
};
