import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ModalContext } from "../context/modalContext";
import { BrandConstants, ScreenTypeDto } from "../models";
import { createPortal } from "react-dom";
import { findBrandConfigByNameAndElementType } from "../utils/utils";
import { ElementType } from "../utils/config";
const MopLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    auth,
    setStep,
    setReturnToScreen,
    onOpen,
    isOpen,
    productCode,
    saizEvents,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  return (
    <div
      style={{ width: "100%" }}
      onClick={() => {
        if (cal?.data?.data?.measuredSize) {
          if (!cal?.isLoaded) {
            getMeasurements();
          }
          setStep(ScreenTypeDto.Summary);
          setReturnToScreen(ScreenTypeDto.Summary);
          saizEvents(ScreenTypeDto.Summary);
        } else {
          setStep(ScreenTypeDto.ScreenOne);
          setReturnToScreen(ScreenTypeDto.ScreenOne);
          saizEvents(ScreenTypeDto.ScreenOne);
        }
        setModal(true);
      }}
      id="saiz-floating-button"
      saiz-widget-flag={
        product?.isLoaded && product?.product?.isActive ? "true" : "false"
      }
      saiz-widget-productcode={productCode}
      saiz-widget-nosize={cal?.isLoaded && cal?.data?.noSize ? "true" : "false"}
      saiz-widget-recommendation={
        !cal?.isLoaded || cal?.data?.noSize
          ? ``
          : `${cal?.data?.data?.measuredSize}`
      }
    ></div>
  );
};

const CommonLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    auth,
    setStep,
    setReturnToScreen,
    target,
    showModal,
    onOpen,
    isOpen,
    brandCode,
    saizEvents,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const target = document
        ?.querySelector(`h2[class='ctl-title']`)
        ?.getBoundingClientRect().top;

      if (window.innerWidth > 600 && target) {
        setVisible(target > 450);
      }
    };
    if (brandCode === BrandConstants.RICHROYALDE) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  return (
    <>
      {product?.isLoaded && product?.product?.isActive && !showModal && (
        <div
          ref={(node) => {
            node?.style.setProperty(
              "display",
              visible ? "flex" : "none",
              "important"
            );
          }}
          onClick={() => {
            if (cal?.data?.data?.measuredSize) {
              if (!cal?.isLoaded) {
                getMeasurements();
              }
              setStep(ScreenTypeDto.Summary);
              saizEvents(ScreenTypeDto.Summary);
              setReturnToScreen(ScreenTypeDto.Summary);
            } else {
              setStep(ScreenTypeDto.ScreenOne);
              saizEvents(ScreenTypeDto.ScreenOne);
              setReturnToScreen(ScreenTypeDto.ScreenOne);
            }
            setModal(true);
          }}
          id="saiz-floating-button"
          className={`slide-in ${target ? "saiz-block-button" : "saiz-fixed"}`}
        >
          <div>
            {!cal?.isLoaded || cal?.data?.noSize ? (
              <>
                <span
                  style={{
                    fontWeight: "var(--saiz_link_desktop_font_weight)",
                  }}
                  className="saiz-button-calculate"

                >
                  {t("calculateSize")}
                </span>
                <span className="saiz-button-calculate-mobile">
                  {product?.product?.brandCode?.toLowerCase() === "bonita"
                    ? t("calculateSize")
                    : t("calculateSizeMobile")}
                </span>
              </>
            ) : (
              <>
                <span className="saiz-best-size">
                  {cal?.data?.data?.measuredSize}
                </span>
                <span className="saiz-best-size-text"> {t("isYourBest")}</span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const TTLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    auth,
    setStep,
    setReturnToScreen,
    target,
    showModal,
    onOpen,
    isOpen,
    saizEvents,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  const [visible, setVisible] = useState(true);
  const handleScroll = () => {
    const target = document
      ?.querySelector(`span[title='Größentabelle']`)
      ?.getBoundingClientRect().top;

    if (window.innerWidth > 600 && target) {
      setVisible(target > 370);
    } else if (window.innerWidth < 600 && target) {
      setVisible(target > 370);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {product?.isLoaded && product?.product?.isActive && !showModal && (
        <div
          ref={(node) => {
            node?.style.setProperty("background", "#001931", "important");
            node?.style.setProperty("box-shadow", "none", "important");
            node?.style.setProperty("top", "350px", "important");
            node?.style.setProperty("z-index", "10", "important");
            node?.style.setProperty(
              "display",
              visible ? "flex" : "none",
              "important"
            );
          }}
          onClick={() => {
            if (cal?.data?.data?.measuredSize) {
              if (!cal?.isLoaded) {
                getMeasurements();
              }
              setStep(ScreenTypeDto.Summary);
              setReturnToScreen(ScreenTypeDto.Summary);
              saizEvents(ScreenTypeDto.Summary);
            } else {
              setStep(ScreenTypeDto.ScreenOne);
              setReturnToScreen(ScreenTypeDto.ScreenOne);
              saizEvents(ScreenTypeDto.ScreenOne);
            }
            setModal(true);
          }}
          id="saiz-floating-button"
          className={`slide-in ${target ? "saiz-block-button" : "saiz-fixed"}`}
        >
          <div
            ref={(node) => {
              node?.style.setProperty("background", "#fff", "important");
            }}
          >
            {!cal?.isLoaded || cal?.data?.noSize ? (
              <>
                <span className="saiz-button-calculate">
                  {t("calculateSize")}
                </span>
                <span className="saiz-button-calculate-mobile">
                  {product?.product?.brandCode?.toLowerCase() === "bonita"
                    ? t("calculateSize")
                    : t("calculateSizeMobile")}
                </span>
              </>
            ) : (
              <>
                <span
                  className="saiz-best-size"
                  ref={(node) => {
                    node?.style.setProperty(
                      "background",
                      "#001931",
                      "important"
                    );
                  }}
                >
                  {cal?.data?.data?.measuredSize}
                </span>
                <span
                  className="saiz-best-size-text"
                  ref={(node) => {
                    node?.style.setProperty("background", "#fff", "important");
                  }}
                >
                  {" "}
                  {t("isYourBest")}
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
const SOLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    setStep,
    setReturnToScreen,
    target,
    showModal,
    saizEvents,
    brandCode,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  const [visible, setVisible] = useState(true);
  const handleScroll = () => {
    const target = document
      ?.querySelector(`div[data-e2e='carousel-similar-products']`)
      ?.getBoundingClientRect().top;

    if (window.innerWidth > 600 && target) {
      setVisible(target > 525);
    } else if (window.innerWidth < 600 && target) {
      setVisible(target > 525);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {product?.isLoaded && product?.product?.isActive && !showModal && (
        <div
          ref={(node) => {
            node?.style.setProperty("background", "#000000", "important");
            node?.style.setProperty("box-shadow", "none", "important");
            node?.style.setProperty(
              "top",
              brandCode === BrandConstants.SOLIVER ? "525px" : "350px",
              "important"
            );
            node?.style.setProperty("z-index", "10", "important");
            node?.style.setProperty(
              "display",
              visible ? "flex" : "none",
              "important"
            );
          }}
          onClick={() => {
            if (cal?.data?.data?.measuredSize) {
              if (!cal?.isLoaded) {
                getMeasurements();
              }
              setStep(ScreenTypeDto.Summary);
              setReturnToScreen(ScreenTypeDto.Summary);
              saizEvents(ScreenTypeDto.Summary);
            } else {
              setStep(ScreenTypeDto.ScreenOne);
              setReturnToScreen(ScreenTypeDto.ScreenOne);
              saizEvents(ScreenTypeDto.ScreenOne);
            }
            setModal(true);
          }}
          id="saiz-floating-button"
          className={`slide-in ${target ? "saiz-block-button" : "saiz-fixed"}`}
        >
          <div
            ref={(node) => {
              node?.style.setProperty("background", "#fff", "important");
            }}
          >
            {!cal?.isLoaded || cal?.data?.noSize ? (
              <>
                <span className="saiz-button-calculate">
                  {t("calculateSize")}
                </span>
                <span className="saiz-button-calculate-mobile">
                  {product?.product?.brandCode?.toLowerCase() === "bonita"
                    ? t("calculateSize")
                    : t("calculateSizeMobile")}
                </span>
              </>
            ) : (
              <>
                <span
                  className="saiz-best-size"
                  ref={(node) => {
                    node?.style.setProperty(
                      "background",
                      "#000000",
                      "important"
                    );
                  }}
                >
                  {cal?.data?.data?.measuredSize}
                </span>
                <span
                  className="saiz-best-size-text"
                  ref={(node) => {
                    node?.style.setProperty("background", "#fff", "important");
                  }}
                >
                  {" "}
                  {t("isYourBest")}
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const TchiboLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    setStep,
    setReturnToScreen,
    target,
    showModal,
    saizEvents,
    brandCode,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  const [hover, setHover] = useState(false);

  return (
    <>
      {product?.isLoaded && product?.product?.isActive && !showModal && (
        <div
          ref={(node) => {
            node?.style.setProperty("position", "initial", "important");
            node?.style.setProperty("display", "flex", "important");
            node?.style.setProperty("background", "transparent", "important");
            node?.style.setProperty("box-shadow", "none", "important");
            node?.style.setProperty("height", "unset", "important");
            node?.style.setProperty(
              "color",
              hover ? "#64696d" : "#1d2429",
              "important"
            );
            node?.style.setProperty(
              "justify-content",
              "flex-start",
              "important"
            );
            node?.style.setProperty(
              "text-decoration",
              "underline",
              "important"
            );
          }}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onClick={() => {
            if (cal?.data?.data?.measuredSize) {
              if (!cal?.isLoaded) {
                getMeasurements();
              }
              setStep(ScreenTypeDto.Summary);
              setReturnToScreen(ScreenTypeDto.Summary);
              saizEvents(ScreenTypeDto.Summary);
            } else {
              setStep(ScreenTypeDto.ScreenOne);
              setReturnToScreen(ScreenTypeDto.ScreenOne);
              saizEvents(ScreenTypeDto.ScreenOne);
            }
            setModal(true);
          }}
          id="saiz-floating-button"
          className="saiz-tchibo-link-button"
        >
          <img
            ref={(node) => {
              node?.style.setProperty(
                "background",
                hover ? "#e9eaea" : "#fff",
                "important"
              );
              node?.style.setProperty("border-radius", "50%", "important");
              node?.style.setProperty("margin-right", "5px", "important");
            }}
            src={`${process.env.REACT_APP_IMAGES_URL}/saiz-tchibo-icon.svg`}
            alt="icon"
          />
          <div
            ref={(node) => {
              node?.style.setProperty("background", "#fff", "important");
              node?.style.setProperty("display", "block", "important");

            }}
          >
            {!cal?.isLoaded || cal?.data?.noSize ? (
              <>
                <span
                  className="saiz-button-calculate
                
                "
                  ref={(node) => {
                    node?.style.setProperty("margin", "0px", "important");
                    node?.style.setProperty("font-weight", "300", "important");
                    node?.style.setProperty("font-size", "16px", "important");
                  }}
                >
                  {t("calculateSize")}
                </span>
                <span
                  className="saiz-button-calculate-mobile"
                  ref={(node) => {
                    node?.style.setProperty("margin", "0px", "important");
                    node?.style.setProperty("font-weight", "300", "important");
                    node?.style.setProperty("font-size", "14px", "important");
                    node?.style.setProperty("text-decoration", "underline", "important");
                  }}
                >
                  {t("calculateSize")}
                </span>
              </>
            ) : (
              <>
                <span
                  className="saiz-best-size-text"
                  ref={(node) => {
                    node?.style.setProperty("background", "#fff", "important");
                    node?.style.setProperty("color", "#21292F", "important");
                    node?.style.setProperty("font-size", "16px", "important");
                    (window.innerWidth <768) ? 
                      node?.style.setProperty("font-size", "14px", "important"):
                      node?.style.setProperty("font-size", "16px", "important");

                    node?.style.setProperty(
                      "padding-right",
                      "0px",
                      "important"
                    );
                    node?.style.setProperty("font-weight", "300", "important");
                  }}
                >
                  {t("isYourBest")}{" "}
                </span>
                <span
                  style={{
                    margin: "0px 0px",
                    fontWeight: 300,
                    color: "#21292F",
                  }}
                  ref={(node) => {
                    (window.innerWidth <768) ? 
                      node?.style.setProperty("font-size", "14px", "important"):
                      node?.style.setProperty("font-size", "16px", "important");
                  }}
                >
                  {cal?.data?.data?.measuredSize + "  "}
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const SaizLink = (props: any) => {
  const [target, setTarget] = useState<HTMLDivElement | null>(null);
  const brandConfig = findBrandConfigByNameAndElementType(
    props.brandCode,
    ElementType.SaizButton
  );
  useEffect(() => {
    if (!brandConfig) return;
    const targetEl = document.querySelector(brandConfig.targetQuerySelector);
    if (targetEl) {
      setTarget(targetEl as HTMLDivElement);
    }
  }, [props.productCode]);

  const getLink = () => {
    switch (props.brandCode) {
      case BrandConstants.MARCOPOLO:
        return <MopLinkContent {...props} target={target} />;
      case BrandConstants.TOMTAILOR:
        return <TTLinkContent {...props} target={target} />;
      case BrandConstants.SOLIVER:
      case BrandConstants.COMMA:
        return <SOLinkContent {...props} target={target} />;
      case BrandConstants.TCHIBO:
        return <TchiboLinkContent {...props} target={target} />;
      default:
        return <CommonLinkContent {...props} target={target} />;
    }
  };
  if (target) {
    target.style.position = "relative";
    return createPortal(getLink(), target);
  }
  return getLink();
};
const mapStateToProps = (state: any, ownProps: any) => {
  return {
    cal: state.CalculatorReducer,
    product: state.ProductReducer,
    auth: state.AuthReducer,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(SaizLink);
