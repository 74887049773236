import { CalculatorService, SessionService } from '../../services'
import { CalculatorConstants } from '../constants'
import { AlertActions } from './alertActions'
import { BodyMeasurementRequestDto, BodyMeasurementResponseDto, BrandSessionDto, SaveBasketSessionCommand } from '../../swagger'
import { logEvent, trackWindowDataLayer } from '../../tracker'

export const CalculatorActions = {
  saveBasketSession,
  recommendationAsync,
  clear,
}
function recommendationAsync(sessonId:string,input: BodyMeasurementRequestDto) {
  return (dispatch: any) => {
    dispatch(AlertActions.clear());
    dispatch(request());
    let response ={} as BodyMeasurementResponseDto;
    CalculatorService.recommendationAsync(sessonId,input).then(
      (data) => {
        dispatch(success(data))
        response = data?.data as BodyMeasurementResponseDto;
        const eventproperties = {
          brandCode: input.brandCode,
          productCode: input.productCode,
          session_id: localStorage.getItem("saiz_session_id"),
          visitorId: input.visitorId,
          user_id: input.visitorId,
          measuredSize: response?.measuredSize,
        };
        trackWindowDataLayer("saiz_Recommendation_Recommendation",eventproperties);
        logEvent("saiz_Recommendation_Done","",eventproperties);
      },
      (error) => {
        dispatch(AlertActions.error(error));
        dispatch(failure(error))
      },
    ).finally(()=>{
        const session = {
          brandCode :input?.brandCode,
          productCode : response.product?.productCode??input?.productCode,
          productName : response.product?.productName??input?.productCode,
          age : input?.age,
          height : input?.height,
          bodyWeight : input?.bodyWeight,
          torsoLength :input?.torsoLength,
          gender : input?.gender,
          bodyShape :input?.bodyShape,
          fitPreference :input?.fitPreference,
          emailAddress :input?.emailAddress,
          chest :input?.chest,
          waist :input?.waist,
          hip :input?.hip,
          isBodyMeasured :input?.isBodyMeasured,
          visitorId: input?.visitorId?? localStorage.getItem('saiz_widget_visitorid'),
          garmentType : response.product?.garmentType,
          saizChest : response?.bodyMeasurement?.chest,
          saizWaist: response?.bodyMeasurement?.waist,
          saizHip: response?.bodyMeasurement?.hip,
          saizLength: response?.bodyMeasurement?.length,
          saizBmi: response?.bodyMeasurement?.bmi,
          saizTorsoLength: response?.bodyMeasurement?.torsoLength,
          calculatedChest: response?.calculatedBodyMeasurement?.chest,
          calculatedWaist: response?.calculatedBodyMeasurement?.waist,
          calculatedHip: response?.calculatedBodyMeasurement?.hip,
          calculatedLength: response?.calculatedBodyMeasurement?.length,
          calculatedBmi:response?.calculatedBodyMeasurement?.bmi,
          calculatedTorsoLength: response?.calculatedBodyMeasurement?.torsoLength,
          deltaPercentageWaist: response?.deltaPercentage?.waist,
          deltaPercentageChest: response?.deltaPercentage?.chest,
          deltaPercentageHip:response?.deltaPercentage?.hip,
          deltaPercentageLength: response?.deltaPercentage?.length,
          deltaPercentageBmi:response?.deltaPercentage?.bmi,
          deltaAverage:response?.deltaPercentage?.average,
          measuredSize: response?.measuredSize,
          exercise:input?.exercise,
        } as BrandSessionDto;
         SessionService.saveSession(sessonId,session);
         logEvent("saiz_Recommender_SessionSave","",session);
       
    });
  }

  function request() {
    return { type: CalculatorConstants.GET_BODY_MEASUREMENT_REQUEST }
  }
  function success(data: any) {
    return { type: CalculatorConstants.GET_BODY_MEASUREMENT_SUCCESS, data }
  }
  function failure(error: any) {
    return { type: CalculatorConstants.GET_BODY_MEASUREMENT_FAILURE, error }
  }
}




function saveBasketSession(sessionId:string,input: SaveBasketSessionCommand) {
  return (dispatch: any) => {
    dispatch(request())
    SessionService.saveBasketSession(sessionId,input).then(
      (data) => {
        dispatch(success())
        dispatch(AlertActions.clear());
      },
      (error) => {
        dispatch(AlertActions.error(error));
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: CalculatorConstants.SAVE_BASKET_SESSION_REQUEST }
  }
  function success() {
    return { type: CalculatorConstants.SAVE_BASKET_SESSION_SUCCESS }
  }
  function failure(error: any) {
    return { type: CalculatorConstants.SAVE_BASKET_SESSION_FAILURE, error }
  }
}


function clear() {
  return (dispatch: any) => {
    dispatch(request())
  }

  function request() {
    return { type: CalculatorConstants.CLEAR_BODY_MEASUREMENT }
  }
}