import { useEffect, useState } from "react";
import { ElementType } from "../../utils/config";
import { findBrandConfigByNameAndElementType } from "../../utils/utils";
import SaizLogo from "./SaizLogo";

type DynamicSizeLogoProps = {
  brandCode: string;
};

const DynamicSaizLogo = ({ brandCode }: DynamicSizeLogoProps) => {
  const [target, setTarget] = useState<HTMLDivElement | null>(null);
  const brandConfig = findBrandConfigByNameAndElementType(
    brandCode,
    ElementType.Image
  );
  useEffect(() => {
    if (!brandConfig) return;
    const targetEl = document.querySelector(brandConfig.targetQuerySelector);
    if (targetEl) setTarget(targetEl as HTMLDivElement);
  }, [brandCode]);

  if (target) return <SaizLogo elem={target} />;
  return <></>;
};

export default DynamicSaizLogo;
